<template>
    <div class="col-12">
        <Editor :id_button="2" />

        <v-dialog v-model="dialog" max-width="500px" v-if="dialog">
            <v-card>
                <v-card-title>
                    <span class="headline"> Редактор навбара </span>
                </v-card-title>

                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field v-model="change_nav.number" label="Порядковый номер"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field v-model="change_nav.name" label="Имя"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field v-model="change_nav.url" label="Url начинающийся с /"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-select
                                    v-model="change_nav.visibility"
                                    :items="visibility_type_list"
                                    label="Видимость"
                                    item-text="info"
                                    item-value="id"
                                ></v-select>
                            </v-flex>
                            <v-flex xs12>
                                <v-select
                                    v-model="change_nav.language"
                                    :items="language_list"
                                    label="Язык"
                                    required
                                    item-text="name"
                                    item-value="id"
                                ></v-select>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="close_dialog()">
                        Отменить
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save_dialog(change_nav)">
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="row">
            <v-btn color="orange" @click="add_nav()">
                <v-icon left>add</v-icon>
                Добавить
            </v-btn>
        </div>

        <filters></filters>

        <v-data-table
            v-if="navbar"
            :headers="headers"
            :items="navbar"
            hide-default-footer
            disable-pagination
            class="elevation-1 row fixed-header"
        >
            <template v-slot:item="{ item }">
                <tr>
                <td>{{ item.number }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">{{ item.url }}</td>
                <td class="text-xs-left">{{ visibility_type[item.visibility] }}</td>
                <td class="text-xs-left">{{ language_dict[item.language].name }}</td>
                <td class="justify-center layout px-0">
                    <v-icon
                        color="blue"
                        class="mr-2"
                        @click="update_nav(item)"
                    >
                        edit
                    </v-icon>
                    <v-icon
                        color="red"
                        @click="delete_nav(item)"
                    >
                        delete
                    </v-icon>
                </td>
                </tr>
            </template>
        </v-data-table>

        <paginator></paginator>

    </div>
</template>

<script>
    import store from '../store';
    import Editor from "./Editor.vue";
    import {POF} from '../api/POF/POF';
    import paginator from '../api/POF/paginator.vue';
    import filters from '../api/POF/filters.vue';
    export default {
        name: "EditorNavbar",
        components:{
            Editor,
            paginator,
            filters,
        },
        data() {
            return {
                headers: [
                    {text: 'Порядковый номер', value: 'number'},
                    {text: 'Имя', value: 'name', sortable: false},
                    {text: 'Url', value: 'url', sortable: false},
                    {text: 'Видимость', value: 'visibility', sortable: false},
                    {text: 'Язык', value: 'language', sortable: false},
                    {text: 'Действия', value: 'actions'}
                ],
                navbar: null,
                dialog: false,
                dialog_new: null,
                change_nav: null,
                visibility_type_list: null,
                visibility_type: null,
                POF: new POF('navbar', 'editor'),
            };
        },
        watch: {
            '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'];
                this.fetchData();
            }
        },
        created() {
            this.POF.update_filters({
                'language': 1,
            });
            this.POF.update_order_field({
                'label': 'Сортировка',
                'value': {
                    'field':  this.POF.order_field,
                    'direction': this.POF.order_direction,
                },
                'width': 2,
                'choice_list': [
                    {
                        'label': 'id, по убыванию',
                        'value': {
                            'field': 'id',
                            'direction': 'desc',
                        },
                    },
                    {
                        'label': 'id, по возрастанию',
                        'value': {
                            'field': 'id',
                            'direction': 'asc',
                        },
                    },
                    {
                        'label': 'Номер, по убыванию',
                        'value': {
                            'field': 'number',
                            'direction': 'desc',
                        },
                    },
                    {
                        'label': 'Номер, по возрастанию',
                        'value': {
                            'field': 'number',
                            'direction': 'asc',
                        },
                    },
                ],
            });
            this.POF.update_filter_fields({
                'f1': {
                    'type': 'choice',
                    'label': 'Язык',
                    'hint': 'Язык материала',
                    'name': 'language',
                    'value': this.POF.filters.language || null,
                    'width': 2,
                    'choice_list': [
                        {
                            'value': null,
                            'label': 'Все языки',
                        },
                        {
                            'value': 1,
                            'label': 'Русский',
                        },
                        {
                            'value': 2,
                            'label': 'English',
                        },
                    ],
                },
                'f2': {
                    'type': 'choice',
                    'label': 'Видимость',
                    'hint': 'Видимость материала',
                    'name': 'visibility',
                    'value': this.POF.filters.visibility || null,
                    'width': 2,
                    'choice_list': [
                        {
                            'value': null,
                            'label': 'Все типы видимости',
                        },
                        {
                            'value': 1,
                            'label': 'Все',
                        },
                        {
                            'value': 2,
                            'label': 'Авторизованные',
                        },
                        {
                            'value': 3,
                            'label': 'Не авторизованные',
                        },
                    ],
                },
            });
            this.fetchData();
        },
        methods: {
            fetchData() {
                console.log('FETCH DATA 1');
                this.lang_id = store.getters['auth/lang_id'];
                this.user_id = store.getters['auth/user_id'];
                this.username = store.getters['auth/username'];
                this.txt = store.getters['summary/texts'];
                this.language_list = store.getters['summary/language_list'];
                this.language_dict = store.getters['summary/language_dict'];
                this.visibility_type_list = store.getters['summary/visibility_type_list'];
                this.visibility_type = store.getters['summary/visibility_type'];

                this.loading = true;
                this.POF.send_query()
                .then(navbar => {
                    this.navbar = navbar.results;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log('query exception', e);
                    this.loading = false;
                });
            },

            get_text(mnemonic) {
                return (this.txt[mnemonic] || {})[this.lang_id];
            },

            delete_nav(nav) {
                let conf = confirm(this.get_text('are_you_sure') + ' DELETE ' + nav.name + '?');
                if (conf === false) {
                    return;
                }

                this.$store.dispatch({
                    type: 'navbar/delete',
                    data: nav,
                    self: this,
                })
                .then(nav => {
                    this.fetchData();
                });
            },

            add_nav() {
                this.change_nav = {
                    'language': 1,
                    'visibility': 2,    // only auth
                };
                this.dialog_new = true;
                this.dialog = true;
            },

            update_nav(nav) {
                this.change_nav = nav;
                this.dialog_new = false;
                this.dialog = true;
            },

            close_dialog() {
                this.dialog = false;
            },

            save_dialog(nav) {
                if (this.dialog_new) {
                    console.log('Создание нового пункта');
                    this.$store.dispatch({
                        type: 'navbar/create',
                        data: nav,
                        self: this,
                    })
                    .then(nav => {
                        this.close_dialog();
                        this.fetchData();
                    })
                } else {
                    this.$store.dispatch({
                        type: 'navbar/update',
                        id: nav.id,
                        data: nav,
                        self: this,
                    })
                    .then(media => {
                        this.close_dialog();
                        this.fetchData();
                    })
                }
            },
        }
    }
</script>

<style>
table {
    width: 100%;
}
</style>