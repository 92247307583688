import API from '../api/api';
import router from '../router';
import {get_item, set_item, del_item,} from './storage';
import {Notice} from "./notice";
import {
    TAG_OK,
    TAG_ERROR,
    TAGS_SET,
    TAG_SET,
    TAG_ADD,
    TAG_DEL,
} from './types';

const TAG_STORAGE_KEY = 'TAG_STORAGE_KEY';
const TAGS_STORAGE_KEY = 'TAGS_STORAGE_KEY';

const initialState = {
    error: false,
    tag: null,
    tags: null,
};

const getters = {
    tags: function (state) {
        return state.tags;
    },
    tag: state => state.tag,
};

const mutations = {
    [TAG_OK](state) {
        console.log('TAG_OK')
        state.error = false;
    },
    [TAG_ERROR](state, do_logout = false) {
        console.log('TAG_ERROR')
        state.error = true;
        if (do_logout) {
            router.push('/logout');
        }
    },
    [TAGS_SET](state, tags) {
        console.log('TAGS_SET ', tags)
        state.error = false;
        set_item(TAGS_STORAGE_KEY, tags);
        state.tags = tags;
    },
    [TAG_SET](state, tag) {
        state.error = false;
        set_item(TAG_STORAGE_KEY, tag);
        state.tag = tag;
    },
    [TAG_ADD](state, tag) {
        console.log('TAG_ADD ', tag)
        state.error = false;
        state.tags = [tag, ...state.tags]
    },
    [TAG_DEL](state, {id}) {
        console.log('TAG_DEL ');
        state.error = false;
        del_item(TAG_STORAGE_KEY);
        state.tag = null;
        if (state.tags.filter) {
            state.tags = state.tags.filter(
                tag => {
                    return tag.id !== id
                }
            );
        }

    },
};


const actions = {
    list({commit}, {data}) {
        console.log('store tag list data=', data);
        return API.tag.list(data)
            .then(function (data) {
                commit(TAGS_SET, data);
                return data
            })
            .catch(function (error) {
                console.log('error: ', error);
                commit(TAG_ERROR);
            });
    },
    get({commit}, {id}) {
        console.log('store tag get, ', id);
        return API.tag.get(id)
            .then(function (data) {
                commit(TAG_SET, data);
                return data;
            })
            .catch(function (error) {
                console.log('error: ', error);
                commit(TAG_ERROR);
            });
    },
    update({commit}, {id, data, self}) {
        console.log('store tag update, ', id, data, self);
        return API.tag.update(id, data)
            .then(function (data) {
                let messg = self.get_text('tag') + ' - ' + self.get_text('update_success');
                console.log('store tag update, ', self);
                Notice.success(
                    messg,
                    self
                );
                commit(TAG_SET, data);
                return data;
            })
            .catch(function (error) {
                let messg = self.get_text('tag') + ' - ' + self.get_text('update_error');
                console.log('error: ', error);
                Notice.error(
                    messg,
                    error,
                    self,
                );
                commit(TAG_ERROR);
            });
    },
    create({commit}, {data, self}) {
        console.log('store tag create, ', data);
        return API.tag.create(data)
            .then(function (data) {
                let messg = self.get_text('tag') + ' - ' + self.get_text('add_success');
                console.log('store tag create, ', self);
                Notice.success(
                    messg,
                    self
                );
                commit(TAG_SET, data);
                return data;
            })
            .catch(function (error) {
                let messg = self.get_text('tag') + ' - ' + self.get_text('add_error');
                console.log('error: ', error);
                Notice.error(
                    messg,
                    error,
                    self
                );
                commit(TAG_ERROR);

            });
    },
    delete({commit}, {data, self}) {
        console.log('store tag delete, ', data, self);
        return API.tag.delete(data)
            .then(function (data) {
                let messg = self.get_text('tag') + ' - ' + self.get_text('delete_success');
                Notice.success(
                   messg,
                    self
                );
                commit(TAG_DEL,data);
                return data;
            })
            .catch(function (error) {
                let messg = self.get_text('tag') + ' - ' + self.get_text('delete_error');
                console.log('error: ', error);
                Notice.error(
                    messg,
                    error,
                    self
                );
                commit(TAG_ERROR);
            });
    },
    search({commit}, {data}) {
        console.log('search=', data);
        return API.search.list(data)
            .then(function (data) {

                return data
            })
            .catch(function (error) {
                console.log('error: ', error);
                commit(TAG_ERROR);
            });
    },



    // нач состояние. Смотрим, если в хранилище есть - просовывыаем
    initialize({commit}) {
        console.log('store tag init');
        const tag = get_item(TAG_STORAGE_KEY);
        const tags = get_item(TAGS_STORAGE_KEY);

        if (tag) {
            commit(TAG_SET, tag);
        } else {
            commit(TAG_SET, null);
        }
        if (tags) {
            commit(TAGS_SET, tags);
        } else {
            commit(TAGS_SET, null);
        }
    },
};


export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
