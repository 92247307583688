<template>
  <div id="home-view">
    <h1>Home</h1>
  </div>
</template>

<script>
    export default {
        name: 'home',
    };
</script>
