
export class Notice {

    static success(mnemonic, self) {
        self.$notice.success({
            title: mnemonic,
            description: mnemonic,
            duration: 5,
        });
    };

    static geterrormsg(error) {
        let strtemp = null;
        if (error.response) {
            if (typeof(error.response.data) == "object") {
                for (var key in error.response.data) {
                    if (typeof(error.response.data[key]) == "object") {
                        strtemp += " " + error.response.data[key][0];
                    } else {
                        strtemp += " " + error.response.data[key];
                    }
                }
            }
            else {
                let listdata = error.response.data.split('\n');
                strtemp = "";
                listdata.forEach(function (item, index, array) {
                    if (index < 3) {
                        strtemp += item + " ";
                    } else {
                        return;
                    }
                });
            }
        } else {
            strtemp += error;
        }
        return strtemp;
    };

    static error(mnemonic, error, self) {
        let description = mnemonic;
        let strerror = this.geterrormsg(error);
        if (strerror) {
            description += "<br/>" + strerror;
        }
        self.$notice.error({
            title: mnemonic,
            description: description,
            duration: 30,
        });
    };
}
