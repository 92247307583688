<template>
<div class="editor-page-list col-12">

    <div class="row loading" v-if="loading">
        <div class="col-12 h-100">
            <div class="row backwhiteopacity"></div>
            <div class="row align-content-center h-100 py-5">
                <div class="col-12">
                    <div class="row justify-content-center">Loading...</div>
                </div>
                <div class="col-12">
                    <div class="row justify-content-center">
                        <i class="material-icons rotateinfinite">autorenew</i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content row" v-if="!loading">
        <div class="col-12">
            <Editor :id_button="0" />
            <div class="row">
                <v-btn color="orange" @click="add()">
                    <v-icon left>add</v-icon>
                    Добавить страницу
                </v-btn>
            </div>

            <filters></filters>

            <v-data-table
                v-if="pages"
                :items="pages"
                item-key="id"
                class="elevation-1 row fixed-header"
                hide-default-footer
                disable-pagination
            >
                <template v-slot:header="{ header }">
                    <thead><tr>
                    <th>
                        <h5>ID</h5>
                    </th>
                    <th>
                        <h5>Тип</h5>
                    </th>
                    <th>
                        <h5>Url</h5>
                    </th>
                    <th>
                        <h5>Заголовок</h5>
                    </th>
                    <th>
                        <h5>Картинка</h5>
                    </th>
                    <th>
                        <h5>Язык</h5>
                    </th>
                    <th>
                        <h5>Создано</h5>
                    </th>
                    <th>
                        <h5>Изменено</h5>
                    </th>
                    <th>
                        <h5>Действия</h5>
                    </th>
                    </tr></thead>
                </template>

                <template v-slot:item="{ item }">
                    <tr>
                    <td>
                        {{ item.id }}
                    </td>
                    <td>
                        {{ page_type[item.page_type] }}
                    </td>
                    <td>
                        <a :href="`/article/${item.name}`">
                            {{ item.name }}
                        </a>
                    </td>
                    <td>
                        {{ item.title }}
                    </td>
                    <td>
                        <v-img
                            v-if="item.image"
                            :src="item.image"
                            :href="`${item.image}`"
                            width="100"
                            height="100"
                        >
                        </v-img>
                    </td>
                    <td>
                        {{ language_dict[item.language].name }}
                    </td>
                    <td>
                        {{ item.create_time | DateTime }}
                    </td>
                    <td>
                        {{ item.update_time | DateTime }}
                    </td>
                    <td class="justify-center layout px-0">
                        <v-icon
                            color="blue"
                            class="mr-2"
                            @click="edit(item.id)"
                        >
                            edit
                        </v-icon>
                        <v-icon
                            color="red"
                            @click="delete_page(item)"
                        >
                            delete
                        </v-icon>
                    </td>
                    </tr>
                </template>

            </v-data-table>

            <paginator></paginator>

        </div>
    </div>
</div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import store from '../store';
    import Editor from "./Editor.vue";
    import {POF} from '../api/POF/POF';
    import paginator from '../api/POF/paginator.vue';
    import filters from '../api/POF/filters.vue';
    export default {
        name: "EditorPageList",
        components:{
            Editor,
            paginator,
            filters,
        },

        computed: mapGetters('auth', [
            'isAuthenticated',
            'username',
            'user_id',
            'is_superuser',
            'is_author',
            'is_staff',
        ]),

        data() {
            return {
                pages: null,
                dialog: false,
                change_account: null,
                profile: null,
                user: null,
                page_type: null,
                loading: false,
                POF: new POF('page_light', 'editor'),
            };
        },
        watch: {
            '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'];
                this.fetchData();
            }
        },
        created() {
            //this.POF.update_order('id', 'desc');
            this.POF.update_filters({
                'language': 1,
            });
            if (!this.is_superuser && !this.is_staff){
                // если не админ можем трогать только свои материалы
                this.POF.update_filters({
                    'user': this.user_id,
                });
            }
            this.POF.update_order_field({
                'label': 'Сортировка',
                'value': {
                    'field':  this.POF.order_field,
                    'direction': this.POF.order_direction,
                },
                'width': 2,
                'choice_list': [
                    {
                        'label': 'id, по убыванию',
                        'value': {
                            'field': 'id',
                            'direction': 'desc',
                        },
                    },
                    {
                        'label': 'id, по возрастанию',
                        'value': {
                            'field': 'id',
                            'direction': 'asc',
                        },
                    },
                    {
                        'label': 'Cоздано, по убыванию',
                        'value': {
                            'field': 'create_time',
                            'direction': 'desc',
                        },
                    },
                    {
                        'label': 'Cоздано, по возрастанию',
                        'value': {
                            'field': 'create_time',
                            'direction': 'asc',
                        },
                    },
                    {
                        'label': 'Изменено, по убыванию',
                        'value': {
                            'field': 'update_time',
                            'direction': 'desc',
                        },
                    },
                    {
                        'label': 'Изменено, по возрастанию',
                        'value': {
                            'field': 'update_time',
                            'direction': 'asc',
                        },
                    },
                ],
            });
            this.POF.update_filter_fields({
                'f1': {
                    'type': 'choice',
                    'label': 'Язык',
                    'hint': 'Язык материала',
                    'name': 'language',
                    'value': this.POF.filters.language || null,
                    'width': 2,
                    'choice_list': [
                        {
                            'value': null,
                            'label': 'Все языки',
                        },
                        {
                            'value': 1,
                            'label': 'Русский',
                        },
                        {
                            'value': 2,
                            'label': 'English',
                        },
                    ],
                },
                'f2': {
                    'type': 'choice',
                    'label': 'Тип',
                    'hint': 'Тип материала',
                    'name': 'page_type',
                    'value': this.POF.filters.page_type || null,
                    'width': 2,
                    'choice_list': [
                        {
                            'value': null,
                            'label': 'Все типы',
                        },
                        {
                            'value': 1,
                            'label': 'Новость',
                        },
                        {
                            'value': 2,
                            'label': 'Блог',
                        },
                        {
                            'value': 3,
                            'label': 'Страница',
                        },
                        {
                            'value': 4,
                            'label': 'Обучение',
                        },
                        {
                            'value': 5,
                            'label': 'Обзор',
                        },
                    ],
                },
                'f3': {
                    'type': 'interval',
                    'label_from': 'ИД, от',
                    'label_to': 'ИД, до',
                    'hint': 'Интервал ИД',
                    'name_from': 'id_from',
                    'name_to': 'id_to',
                    'value_from': this.POF.filters.id_from || null,
                    'value_to': this.POF.filters.id_to || null,
                    'width': 3,
                },
                'f4': {
                    'type': 'search',
                    'label': 'Поиск',
                    'hint': 'Поиск',
                    'name': 'search',
                    'value': this.POF.filters.search || null,
                    'width': 2,
                },
            });
            this.fetchData();
        },

        methods: {
            fetchData(params = {}) {
                console.log('FETCH DATA');
                this.lang_id = store.getters['auth/lang_id'];
                this.txt = store.getters['summary/texts'];
                this.language_list = store.getters['summary/language_list'];
                this.language_dict = store.getters['summary/language_dict'];
                this.page_type = store.getters['summary/page_type'];

                this.loading = true;
                this.POF.send_query()
                .then(pages => {
                    this.pages = pages.results;
                    this.loading = false;
                    console.log('Have pages:', this.pages);
                })
                .catch((e) => {
                    console.log('query exception', e);
                    this.loading = false;
                });
            },

            get_text(mnemonic) {
                return (this.txt[mnemonic] || {})[this.lang_id];
            },

            add() {
                this.$router.push('/editor/page/new');
            },

            edit(id) {
                this.$router.push('/editor/page/' + id)
            },

            delete_page(page) {
                let conf = confirm(this.get_text('are_you_sure') + ' DELETE ' + page.name + '?');
                if (conf === false) {
                    return;
                }

                console.log('start delete page');
                this.loading = true;
                this.$store.dispatch({
                    type: 'page/delete',
                    data: page,
                    self: this,
                })
                .then(page => {
                    this.loading = false;
                    this.fetchData();
                });
            }
        }
    }
</script>

<style>
table {
    width: 100%;
}
</style>