import APIconstructor from './base';
import {APIconstructorCustom} from './base';

const data = new APIconstructor('data');

const page = new APIconstructor('page');
const page_light = new APIconstructor('page_light');

const mediafile = new APIconstructor('mediafile');
const navbar = new APIconstructor('navbar');
const tag = new APIconstructor('tag');

const profile = new APIconstructor('profile');
const user = new APIconstructor('user');

const summary = new APIconstructor('summary');

const register = new APIconstructorCustom('register');
const reset = new APIconstructorCustom('reset');


const API = {
    data: data,

    page: page,
    page_light: page_light,

    mediafile: mediafile,
    navbar: navbar,
    tag: tag,

    profile: profile,
    user: user,
    register: register,
    reset: reset,

    summary: summary,
}

export default API;
