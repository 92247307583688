<template>
    <v-container grid-list-xl>
        <v-layout row wrap>
            <v-flex xs6 offset-xs4 column wrap>
                <v-card>
                    <v-flex xs12>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <br>
                            <h3>{{get_text('reset_password')}}</h3>
                            <br>
                            <v-text-field
                                    v-model="username"
                                    :label="get_text('username')"
                                    :placeholder="get_text('username')"
                                    :rules="required"
                                    maxlength="20"
                                    required
                            ></v-text-field>
                            <v-btn
                                    v-if="txt"
                                    @click="reset(username)"
                                    :disabled="!valid"
                                    id="login-button"
                                    color="green"
                            >
                                   {{get_text('reset')}}
                            </v-btn>
                            <v-btn
                                    v-if="txt"
                                    @click="login()"
                                    id="login-button"
                                    color="orange"
                            >
                                   {{get_text('login')}}
                            </v-btn>
                            <br>
                            <span v-if="status==='1'" style="color: green;">{{get_text('reset_ok')}}</span>
                            <span v-if="status==='2'" style="color: red;">{{get_text('reset_error')}}</span>
                            <span v-if="status==='3'" style="color: red;">{{error_message}}</span>
                        </v-form>
                    </v-flex>
                </v-card>
                <br>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import store from '../store';
    import {Notice} from "../store/notice";

    export default {
        data() {
            return {
                status: '0',
                username: '',
                error_message: '',
                valid: false,
                txt: null,
                lang_id: null,
                required: [
                    (v) => !!v || this.get_text('required'),
                ],
            };
        },

        created() {
            this.fetchData();
        },

        watch: {
            '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'] || 1;
                this.fetchData();
            },
        },

        methods: {
            reset(username) {
                this.$store.dispatch({
                    type: 'message/reset',
                    data: {
                        username: username,
                    },
                }).then(mes => {
                    if (mes.status==='Error') {
                        if (mes.message==='Нет пользователя с таким именем') {
                            this.status = '2';
                            let self = this;
                            let messg = this.get_text('reset_error');
                            Notice.error(messg, '  No such username', self);
                        }
                        else {
                            this.error_message = mes.message;
                            this.status = '3';
                        }
                    }
                    else {
                        this.status = '1';
                        let self = this;
                        let messg = this.get_text('reset_ok');
                        Notice.success(messg, self);
                    }
                });
            },

            fetchData() {
                this.txt = store.getters['summary/texts'] || {};
                this.lang_id = store.getters['auth/lang_id'] || 1;
            },

            get_text(mnemonic) {
                return (this.txt[mnemonic] || {})[this.lang_id];
            },
            login(){
                this.$router.push('/login')
            },
        },
    };

</script>

<style scoped>

</style>