import auth from '../api/auth';
import session from '../api/session';
import {get_item, set_item, del_item,} from './storage';
import {
    LOGIN_BEGIN,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    REMOVE_TOKEN,
    SET_TOKEN,
    SET_LANGUAGE,
} from './types';

const TOKEN_STORAGE_KEY = 'TOKEN_STORAGE_KEY';
const UID_STORAGE_KEY = 'UID_STORAGE_KEY';
const LANG_STORAGE_KEY = 'LANG_STORAGE_KEY';
const ROLES_STORAGE_KEY = 'ROLES_STORAGE_KEY';
const STAFF_STORAGE_KEY = 'STAFF_STORAGE_KEY';
const SUPERUSER_STORAGE_KEY = 'SUPERUSER_STORAGE_KEY';
const USERNAME_STORAGE_KEY = 'USERNAME_STORAGE_KEY';

const initialState = {
    authenticating: false,
    error: false,
    token: null,
    user_id: null,
    language_id: 1,
    staff: false,
    superuser: false,
    username: null,
    my_roles: [],
};

const getters = {
    user_id: state => state.user_id,
    lang_id: state => state.language_id,
    username: state => state.username,
    error: state => state.error,
    isAuthenticated: state => !!state.token,
    is_staff: state => state.staff,
    is_superuser: state => state.superuser,
    is_author: function(state){
        var auth = ['Author', 'Автор', 'Trusted Author', 'Доверенный автор'];
        var iauth = false;
        auth.forEach(function(entry) {
            if (state.superuser){
                console.log('Superamin is author!');
                iauth = true;
            }
            if (state.my_roles.includes(entry)){
                console.log('Found right: ' + entry);
                iauth = true
                return true;
            }
        });
        console.log('is_author=' + iauth);
        return iauth;
    },
    my_roles: state => state.my_roles,
};

const actions = {
    // залогиниться. Шлем запрос. При удаче - сохраняем токен, делаем состояние логин удачен
    login({commit}, {data}) {
        console.log('store auth login');
        commit(LOGIN_BEGIN);
        return auth.login(data.username, data.password)
            .then(({ data }) => commit(SET_TOKEN, data))
            .then(() => {
                commit(LOGIN_SUCCESS);
            })
            .catch(() => {
                commit(LOGIN_FAILURE);
            });
    },

    // разлогиниться. Уничтожаем токен, чем бы там все не кончилось
    logout({commit}) {
        console.log('store auth logout');
        return auth.logout()
            .then(() => commit(LOGOUT))
            .catch(function (e) {
                //console.log('Logout exception: ', e);
                commit(LOGIN_FAILURE);
            })
            .finally(() => commit(REMOVE_TOKEN));
    },

    change_password({ commit }, { username, password, new_password, self }) {
        console.log('store auth change_password');
        return auth.change_password(username, password, new_password)
        .then(
            ({data}) => Notice.success(self.get_text('password') + ' - ' + self.get_text('update_success'), self)
        )
        .catch(() => Notice.error(self.get_text('password') + ' - ' + self.get_text('update_error'), '', self))
    },


    // нач состояние. Смотрим, если в хранилище есть токен - просовывыаем его в хидер
    initialize({commit}) {
        console.log('store auth init', commit);
        const data = {
            token: get_item(TOKEN_STORAGE_KEY),
            user_id: get_item(UID_STORAGE_KEY),
            language_id: get_item(LANG_STORAGE_KEY),
            staff: get_item(STAFF_STORAGE_KEY),
            superuser: get_item(SUPERUSER_STORAGE_KEY),
            username: get_item(USERNAME_STORAGE_KEY),
            my_roles: get_item(ROLES_STORAGE_KEY),
        };
        if (data.token) {
            commit(SET_TOKEN, data);
        } else {
            commit(REMOVE_TOKEN);
        }
    },

    //
    set_language({commit}, {lang}) {
        console.log('store auth set lang=', lang);
        if (!lang) {
            lang = 1;
        }
        commit(SET_LANGUAGE, lang);
    }

};

const mutations = {
    [LOGIN_BEGIN](state) {
        state.authenticating = true;
        state.error = false;
    },
    [LOGIN_FAILURE](state) {
        state.authenticating = false;
        state.error = true;
    },
    [LOGIN_SUCCESS](state) {
        state.authenticating = false;
        state.error = false;
    },
    [LOGOUT](state) {
        state.authenticating = false;
        state.error = false;
        state.username = null;
        state.superuser = null;
        state.staff = null;
    },
    // сохраняем токен. При этом зафигачиваем в хидер его
    [SET_TOKEN](state, data) {
        console.log("SET_TOKEN ", data);
        set_item(TOKEN_STORAGE_KEY, data.token);
        set_item(UID_STORAGE_KEY, data.user_id);
        set_item(LANG_STORAGE_KEY, data.language_id);
        set_item(STAFF_STORAGE_KEY, data.staff);
        set_item(SUPERUSER_STORAGE_KEY, data.superuser);
        set_item(USERNAME_STORAGE_KEY, data.username);
        set_item(ROLES_STORAGE_KEY, data.my_roles);
        state.token = data.token;
        state.user_id = data.user_id;
        state.language_id = data.language_id;
        state.staff = data.staff;
        state.superuser = data.superuser;
        state.username = data.username;
        state.my_roles = data.my_roles;
        session.defaults.headers.Authorization = `Token ${data.token}`;
    },
    // убираем токен, в тч их хидера
    [REMOVE_TOKEN](state) {
        console.log("REMOVE_TOKEN");
        del_item(TOKEN_STORAGE_KEY);
        del_item(UID_STORAGE_KEY);
        del_item(LANG_STORAGE_KEY);
        del_item(STAFF_STORAGE_KEY);
        del_item(SUPERUSER_STORAGE_KEY);
        del_item(USERNAME_STORAGE_KEY);
        del_item(ROLES_STORAGE_KEY);

        delete session.defaults.headers.Authorization;
        state.token = null;
    },
    [SET_LANGUAGE](state, lang) {
        console.log("SET_LANGUAGE ", lang);
        state.language_id = lang;
        set_item(LANG_STORAGE_KEY, lang);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
