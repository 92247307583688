<template>
<div class="paginator">
    <br>

    <div class="row align-items-center justify-content-center">

        <div class="col-4 no-padding" v-if="parent.POF.labels.page">
            <span class="mx-3">
                {{ parent.POF.labels.page }}:
            </span>

            <v-btn
                :disabled="parent.POF.page <= 1"
                @click="parent.POF.prev_page(), parent.fetchData()"
            >
                <v-icon>arrow_back</v-icon>
            </v-btn>

            <span class="mx-3">
                {{ parent.POF.page }} / {{ parent.POF.pages_count }}
            </span>

            <v-btn
                :disabled="parent.POF.page >= parent.POF.pages_count"
                @click="parent.POF.next_page(), parent.fetchData()"
            >
                <v-icon>arrow_forward</v-icon>
            </v-btn>
        </div>

        <div class="col-1 no-padding" v-if="parent.POF.fixed_page_step == false">
            <v-text-field
                name="page_input"
                v-model="parent.POF.want_page"
                :disabled="parent.POF.pages_count < 2"
                :label="parent.POF.labels.go || 'Перейти'"
                :placeholder="parent.POF.labels.page || 'Страница'"
                outlined
                hide-details
                type="number"
            >
            </v-text-field>
        </div>

        <div class="col-1 no-padding" v-if="parent.POF.fixed_page_step == false">
            <v-btn
                :disabled="parent.POF.pages_count < 2"
                @click="parent.POF.goto_page(parent.POF.want_page), parent.fetchData()"
            >
                <v-icon>arrow_forward</v-icon>
            </v-btn>
        </div>

        <div class="col-4 no-padding" v-if="parent.POF.fixed_page_size == false">
            <span class="mx-3" v-if="parent.POF.labels.page_size">
                {{ parent.POF.labels.page_size }}:
            </span>

            <v-btn
                :disabled="parent.POF.page_size == 5"
                @click="parent.POF.set_page_size(5), parent.fetchData()"
            >
                5
            </v-btn>

            <v-btn
                :disabled="parent.POF.page_size == 10"
                @click="parent.POF.set_page_size(10), parent.fetchData()"
            >
                10
            </v-btn>

            <v-btn
                :disabled="parent.POF.page_size == 20"
                @click="parent.POF.set_page_size(20), parent.fetchData()"
            >
                20
            </v-btn>

            <v-btn
                :disabled="parent.POF.page_size == 50"
                @click="parent.POF.set_page_size(50), parent.fetchData()"
            >
                50
            </v-btn>
        </div>

    </div>

    <br>
</div>
</template>

<script>
    // https://vuejs.org/v2/guide/components.html
    export default {
        name: "paginator",
        //props: [
        //    'parent',
        //],
        data() {
            return {
                'parent': this.$parent,
            };
        },
    }
</script>

<style>
.no-padding{
    padding: 3px !important;
}
</style>