import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import auth from './auth';
import data from './data';

import page from './page';
import page_light from './page_light';

import mediafile from './mediafile';
import navbar from './navbar';
import tag from './tag';

import summary from './summary';

const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        data,

        page,
        page_light,

        mediafile,
        navbar,
        tag,

        summary,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
});
