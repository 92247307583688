<template>
    <div class="row">

    <div id="bje-padd" class="col-1">
    </div>

    <div id="bje-view" class="col-3">
        <div class="row">
            <h1>Расчет БЖЕ</h1>
        </div>

        <form @submit.prevent="submit">
            <div class="row">
                <h2>Всего пищи, гр:</h2>
            </div>
            <div class="row">
                <input
                    v-model="inputs.all"
                    type="number"
                    id="all"
                    placeholder="Всего"
                    class="col-5 bordered"
                >
            </div>

            <div class="row">
                <h2>Белков на 100 гр:</h2>
            </div>
            <div class="row">
                <input
                    v-model="inputs.protein_p"
                    type="number"
                    id="protein_p"
                    placeholder="Белков на 100 гр"
                    class="col-5 bordered"
                >
            </div>

            <div class="row">
                <h2>Жиров на 100 гр:</h2>
            </div>
            <div class="row">
                <input
                    v-model="inputs.fat_p"
                    type="number"
                    id="fat_p"
                    placeholder="Жиров на 100 гр"
                    class="col-5 bordered"
                >
            </div>
        </form>

        <div class="row">
            <v-btn
                @click="count_pf()"
                id="pf-button"
                :disabled="!inputs.fat_p || !inputs.protein_p || !inputs.all"
            >
                Рассчитать белки-жиры
            </v-btn>
        </div>
    </div>

    <div id="bje-view2" class="col-3">
        <form @submit.prevent="submit">
            <div class="row">
                <h2>Белков всего, гр:</h2>
            </div>
            <div class="row">
                <input
                    v-model="inputs.protein"
                    type="number"
                    id="protein"
                    placeholder="Белков всего, гр"
                    class="col-5 bordered"
                >
            </div>

            <div class="row">
                <h2>Жиров всего, гр:</h2>
            </div>
            <div class="row">
                <input
                    v-model="inputs.fat"
                    type="number"
                    id="fat"
                    placeholder="Жиров всего, гр"
                    class="col-5 bordered"
                >
            </div>
        </form>

        <div class="row">
            <v-btn
                @click="count_bje()"
                id="bje-button"
                :disabled="!inputs.fat || !inputs.protein"
            >
                Рассчитать БЖЕ
            </v-btn>
        </div>
    </div>

    <div id="bje-view2" class="col-3">
        <div class="row">
            <h2>БЖЕ:</h2>
        </div>
        <div class="row">
            <input
                v-model="bje"
                type="number"
                id="bje"
                placeholder="БЖЕ"
                class="col-5 bordered"
                disabled
            >
        </div>
        <br>
        <div class="row" v-if="bje_time_len > 0">
            <h2>Распределение по времени:</h2>
        </div>
        <div class="row" v-if="bje_time_len > 0">
            <table border="1">
                <tr>
                    <th v-for="val in bje_time_len">
                        Час {{ val }}
                    </th>
                </tr>
                <tr>
                    <td v-for="val in bje_time">
                        {{ val }}
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div id="bje-padd" class="col-1">
    </div>

    </div>
</template>

<script>
    import store from '../store';
    export default {
        data() {
            return {
                inputs: {
                    all: null,
                    protein_p: null,
                    fat_p: null,
                    protein: null,
                    fat: null,
                },
                bje: 0,
                bje_time: [],
                bje_time_len: 0,
            };
        },
        methods: {
            count_pf(){
                this.inputs.protein = this.inputs.all * this.inputs.protein_p / 100;
                this.inputs.fat = this.inputs.all * this.inputs.fat_p / 100;
                this.count_bje();
            },
            count_bje(){
                var fat_bje = this.inputs.fat * 9.3 / 100;
                var prot_bje = this.inputs.protein * 4.1 / 100;
                this.bje = Number((fat_bje + prot_bje).toFixed(1));
                this.bje_time = [];
                var t = 0;
                if (this.bje <= 1.5){
                    // for 3 hours
                    t = 3;
                } else if (this.bje <= 2.5){
                    // for 4 hours
                    t = 4;
                } else if (this.bje <= 3.5){
                    // for 5 hours
                    t = 5;
                } else {
                    // bje >= 4. for 7 hours
                    t = 7;
                }
                console.log(t);
                for (var i = 0; i < t; i++) {
                    this.bje_time.push((this.bje / t).toFixed(1));
                }
                console.log(this.bje_time);
                this.bje_time_len = t;
            },
        },
    };
</script>

<style>
    form input {
        display: block
    }
</style>
