const VERSION = "01.10.2019 15:43";

import API from '../api/api';
import router from '../router';
import { get_item, set_item, del_item, } from './storage';
import {
    SUMMARY_OK,
    SUMMARY_SET,
    SUMMARY_DEL,
    SUMMARY_ERROR,
} from './types';

const SUMMARY_STORAGE_KEY = 'SUMMARY_STORAGE_KEY';

const initialState = {
    error: false,
    loaded: false,
    data: {
        language_list:          null,
        language_dict:          null,
        page_type:              null,
        page_type_list:         null,
        visibility_type:        null,
        visibility_type_list:   null,
        texts:                  null,
        roles:                  null,
        version:                null,
        pagination:             null,
        us:                     0,
    },
};

const getters = {
    language_dict:              state => state.data.language_dict,
    language_list:              state => state.data.language_list,
    user_fullname:              state => state.data.user_fullname,
    texts:                      state => state.data.texts,
    roles:                      state => state.data.roles,
    loaded:                     state => state.loaded,
    version:                    state => state.data.version,
    page_type_list:             state => state.data.page_type_list,
    page_type:                  state => state.data.page_type,
    visibility_type_list:       state => state.data.visibility_type_list,
    visibility_type:            state => state.data.visibility_type,
    pagination:                 state => state.data.pagination,
    us:                         state => state.data.us,

};

const actions = {
    // берем данные из апишки
    get({ commit, dispatch }) {
        console.log('store summary get from API');
        return API.summary.list()
            .then((data) => commit(SUMMARY_SET, data))
            .then(() => commit(SUMMARY_OK))
            .catch(function(){
                console.log('SUMMARY_ERROR logout!');
                commit(SUMMARY_ERROR);
            });
    },

    // нач состояние. Смотрим, если в хранилище есть токен - просовывыаем его в хидер
    initialize({ commit }) {
        const data = get_item(SUMMARY_STORAGE_KEY);
        if (data) {
            console.log('have saved summary');
            commit(SUMMARY_SET, data);
            commit(SUMMARY_OK);
        } else {
            console.log('init empty summary');
            commit(SUMMARY_DEL);
        }
    },
};

const mutations = {
    [SUMMARY_ERROR](state) {
        console.log('SUMMARY_ERROR');
        state.error = true;
        state.loaded = false;
        router.push('/logout');
    },
    [SUMMARY_OK](state) {
        console.log('SUMMARY_OK');
        state.error = false;
        state.loaded = true;
    },
    // сохраняем
    [SUMMARY_SET](state, data) {
        console.log('SUMMARY_SET ', data);
        if (state.data.version !== data.version && state.data.version !== null) {
            state.data.version = data.version;
            window.location.reload(true);
        }
        set_item(SUMMARY_STORAGE_KEY, data);
        state.data = data;
    },
    // убираем
    [SUMMARY_DEL](state) {
        console.log('SUMMARY_DEL');
        del_item(SUMMARY_STORAGE_KEY);
        state.data = initialState.data;
        state.loaded = false;
    },

};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
