<template>
    <div class="col-12 py-0">

        <div class="row wrap info justify-start">

            <div class="col-2 info wrap align-center">
                <a href="/">
                    <v-img
                        src="/img/ava.png"
                        style="max-width: 80px; max-height: 80px;"
                    >
                    </v-img>
                </a>
            </div>

            <v-spacer></v-spacer>

            <div class="col-10 col-lg-8 info wrap align-center">
                <v-layout align-center justify-center row fill-height>
                    <div
                        class="main-text"
                        style="color: white;
                        letter-spacing: 5px;
                        padding-bottom: 5px;
                        padding-right: 5px;
                        font-family: 'Roboto',sans-serif;
                    ">
                        Нюра Шарикова - о жизни с сахарным диабетом
                    </div>
                </v-layout>
            </div>

            <v-spacer></v-spacer>

            <div class="col-12 col-lg-2 info xs2 align-center wrap row justify-center icons-wrapper">
                <!--
                <a href="https://www.instagram.com/dia_status/" target="_blank">
                    <v-icon color="white">
                        mdi-instagram
                    </v-icon>
                </a>
                -->
                <a href="https://www.youtube.com/dia_status" target="_blank">
                    <v-icon color="white">
                        mdi-youtube
                    </v-icon>
                </a>
                <a href="https://vk.com/dia_status" target="_blank">
                    <v-icon color="white">
                        mdi-vk
                    </v-icon>
                </a>
                <a href="https://ok.ru/dia.status" target="_blank">
                    <v-icon color="white">
                        mdi-odnoklassniki
                    </v-icon>
                </a>
                <a href="https://zen.yandex.ru/diabet" target="_blank">
                    <v-icon color="white">
                        mdi-yin-yang
                    </v-icon>
                </a>
                <!--
                <a href="https://www.facebook.com/diastatus" target="_blank">
                    <v-icon color="white">
                        mdi-facebook
                    </v-icon>
                </a>
                -->
                <a href="https://t.me/dia_status" target="_blank">
                    <v-icon color="white">
                        mdi-telegram
                    </v-icon>
                </a>
            </div>


            <!--
            <v-col info xs3 pt-3 align-center >
                <v-toolbar dense color=info text align-center justify-end >
                    <v-spacer></v-spacer>
                    <v-toolbar-items v-if="lang_list" >
                        <v-btn text disabled>
                            {{ get_text('language') }}:
                        </v-btn>

                        <v-btn
                            v-if="lang_id === 1"
                            text
                            class="v-btn--active"
                            @click="choose_lang(1)"
                        >
                            {{ lang_list[1]['code'] }}
                        </v-btn>

                        <v-btn
                            v-else
                            text
                            @click="choose_lang(1)
                        ">
                            {{ lang_list[1]['code'] }}
                        </v-btn>

                        <v-btn
                            v-if="lang_id === 2"
                            text
                            class="v-btn--active"
                           @click="choose_lang(2)"
                        >
                            {{ lang_list[2]['code'] }}
                        </v-btn>

                        <v-btn
                            v-else
                            text
                           @click="choose_lang(2)"
                        >
                            {{ lang_list[2]['code'] }}
                        </v-btn>

                        <v-btn
                            v-if="lang_id === 7"
                            text
                            class="v-btn--active"
                            @click="choose_lang(7)"
                        >
                            {{ lang_list[7]['code'] }}
                        </v-btn>

                        <v-btn
                            v-else
                            text
                           @click="choose_lang(7)"
                        >
                            {{ lang_list[7]['code'] }}
                        </v-btn>

                    </v-toolbar-items>
                </v-toolbar>
             </v-col>
             -->

        </div>

    </div>
</template>

<script>
    import store from '../store';
    import {mapGetters} from 'vuex';

    export default {

        name: "Header",

        data() {
            return {
                lang_id: null,
                lang_list: null,
                txt: null,
                us: null,
            }
        },

        created() {
            this.fetchData();
        },

        watch: {
             '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'] || 1;
                this.fetchData();
            },
             '$store.state.summary.data.us'() {
                this.us = store.getters['summary/us'] || 0;
            },
        },

        methods: {
              fetchData() {
                  this.lang_list = store.getters['summary/language_dict'];
                  this.txt = store.getters['summary/texts'] || {};
                  this.lang_id = store.getters['auth/lang_id'] || 1;
                  this.us = store.getters['summary/us'] || 0;
              },

              choose_lang(lang) {
                console.log('Navbar choose lang=', lang);
                this.$store.dispatch({
                    type: 'auth/set_language',
                    lang: lang,
                })
                .then(lang_id => {
                    this.lang_id = lang;
                    console.log('Language set!');
                });
            },

              get_text(mnemonic) {
                return (this.txt[mnemonic] || {})[this.lang_id];
            },
        },
    }
</script>

<style scoped>
    .fb-img {
        margin-left: 10px;
        margin-right: 10px;
    }
    .speech-bubble {
        position: relative;
        background: #ffffff;
        border-radius: .5em;
        margin-bottom: 15px;
        height: 35px;
    }
    .speech-bubble:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 80%;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-top-color: #ffffff;
        border-bottom: 0;
        border-left: 0;
        margin-left: -3.5px;
        margin-bottom: -7px;
    }

    .icons-wrapper i {
      font-size: 36px;
    }

    .main-text {
      font-size: 33px;
    }

    @media (max-width: 992px) {
      .icons-wrapper i {
        font-size: 54px;
      }

      .icons-wrapper a {
        margin-right: 10px;
      }

      .icons-wrapper a:last-child {
        margin-right: 0;
      }

      .main-text {
        font-size: 26px;
      }
    }
</style>