<template>
  <div id="lost-view">
    <h1>Страница не найдена</h1>
    <router-link to="/">На главную</router-link>
  </div>
</template>

<script>
export default {
  name: 'lost',
};
</script>
