<template>
<div class="filters">
    <div class="row align-items-center justify-content-center">

        <div
            v-for="(val, key, index) in parent.POF.filter_fields"
            :class="['col-' + val.width + ' no-padding']"
        >

            <div
                class="row"
                v-if="val.type == 'order'"
            >
                <div class="col-10 no-padding">
                    <label :for="order_select">
                        {{ val.label }}:
                    </label>
                    <select
                        v-model="val.value"
                        id="order_select"
                        @change="parent.POF.update_order(val.value.field, val.value.direction), parent.fetchData()"
                        class="custom-select"
                    >
                        <option
                            v-for="choice in val.choice_list"
                            :value="choice.value"
                        >
                            {{ choice.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div
                class="row"
                v-if="val.type == 'number'"
            >
                <div class="col-10 no-padding">
                    <label :for="key">
                        {{ val.label }} ({{ val.min }} - {{ val.max }}): 
                    </label>
                    <input
                        v-model="val.value"
                        :id="key"
                        @change="parent.POF.update_filter(val.name, val.value), parent.fetchData()"
                        type="number"
                        :min="val.min"
                        :max="val.max"
                        class="form-control"
                        minlength="1"
                        maxlength="10"
                    >
                    </input>
                </div>
            </div>

            <div
                class="row"
                v-if="val.type == 'interval'"
            >
                <div class="col-5 no-padding">
                    <label :for="key + 'from'">
                        {{ val.label_from }}: 
                    </label>
                    <input
                        v-model="val.value_from"
                        :id="key + '_from'"
                        @change="parent.POF.update_filter(val.name_from, val.value_from), parent.fetchData()"
                        type="number"
                        class="form-control"
                    >
                    </input>
                </div>
                <div class="col-auto no-padding">
                    <div class="row px-2">
                        -
                    </div>
                </div>
                <div class="col-5 no-padding">
                    <label :for="key + '_to'">
                        {{ val.label_to }}: 
                    </label>
                    <input
                        v-model="val.value_to"
                        :id="key + '_to'"
                        @change="parent.POF.update_filter(val.name_to, val.value_to), parent.fetchData()"
                        type="number"
                        class="form-control"
                        minlength="1"
                        maxlength="10"
                    >
                    </input>
                </div>
            </div>

            <div
                class="row"
                v-if="val.type == 'choice'"
            >
                <div class="col-10 no-padding">
                    <label :for="key">
                        {{ val.label }}:
                    </label>
                    <select
                        v-model="val.value"
                        :id="key"
                        @change="parent.POF.update_filter(val.name, val.value), parent.fetchData()"
                        class="custom-select"
                    >
                        <option
                            v-for="choice in val.choice_list"
                            :value="choice.value"
                        >
                            {{ choice.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div
                class="row"
                v-if="val.type == 'search'"
            >
                <div class="col-10 no-padding">
                    <label :for="key">
                        {{ val.label }}:
                    </label>
                    <input
                        v-model="val.value"
                        :id="key"
                        @change="parent.POF.update_filter('search', val.value), parent.fetchData()"
                        type="text"
                        class="form-control"
                        minlength="3"
                        maxlength="20"
                    >
                    </input>
                </div>
            </div>

        </div>

    </div>
</div>
</template>

<script>
    // https://vuejs.org/v2/guide/components.html
    export default {
        name: "filters",
        //props: [
        //    'parent',
        //],
        data() {
            return {
                'parent': this.$parent,
            };
        },
    }
</script>

<style>
.no-padding{
    padding: 3px !important;
}
</style>