<template>
<div class="pages-list col-12">

    <div class="row loading" v-if="loading">
        <div class="col-12 h-100">
            <div class="row backwhiteopacity"></div>
            <div class="row align-content-center h-100 py-5">
                <div class="col-12">
                    <div class="row justify-content-center">Loading...</div>
                </div>
                <div class="col-12">
                    <div class="row justify-content-center">
                        <i class="material-icons rotateinfinite">autorenew</i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content row" v-if="!loading">
    <div v-if="pages" class="container-xl">
        <v-row>
            <v-col>
                <v-card v-for="page in pages" :to="`/article/${page.name}`" :key="page.name">
                    <v-col>
                        <div class="item-wrapper">
                            <div>
                                <v-avatar
                                  size="150"
                                  tile
                                >
                                    <v-img :src="page.image"></v-img>
                                </v-avatar>
                            </div>

                            <div>
                                <v-card-title
                                    class="headline"
                                    v-text="page.title"
                                >
                                </v-card-title>

                                <v-card-text>
                                    <div>
                                        {{ page.create_time | Date }}
                                    </div>
                                    <div>
                                        {{ page.sub_title }}
                                    </div>
                                </v-card-text>
                            </div>
                        </div>

                    </v-col>
                </v-card>

                <paginator></paginator>

            </v-col>
        </v-row>
    </div>

    <div v-else id="lost-view">
        <h1>{{get_text('page_not_found')}}</h1>
        <h4>{{get_text('one_of_us_is_lost')}}</h4>
        <router-link to="/">{{get_text('take_me_home')}}</router-link>
    </div>
    </div>

</div>
</template>

<script>
    import store from '../store';
    import {POF} from '../api/POF/POF';
    import paginator from '../api/POF/paginator.vue';
    import filters from '../api/POF/filters.vue';
    export default {
        name: "PL",
        components: {
            'paginator': paginator,
        },
        data() {
            return {
                pageType: 2,            // blog
                lastPage_type: 0,
                loading: false,
                txt: {},
                lang_id: null,
                pages: null,
                POF: new POF('page_light', 'pages_list'),
            };
        },
        watch: {
            '$route.params': function (id) {
                this.fetchData();
            },
            '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'];
                this.fetchData();
            },
        },
        created() {
            this.POF.fixed_page_size = true;
            this.POF.fixed_page_step = true;
            this.POF.update_filters({
                'language': 1,
            });
            this.POF.set_page_size(20);
            //this.POF.update_order('id', 'desc');
            this.POF.update_order('create_time', 'desc');
            this.fetchData();
        },
        methods: {
            fetchData() {
                console.log('PL fetchData');

                this.txt = store.getters['summary/texts'] || {};
                this.lang_id = store.getters['auth/lang_id'] || 1;

                var route = this.$route;
                console.log('route', route);

                /*
                NEWS = 1            # страница с перепечаткой новости
                BLOG = 2            # запись блога, свой контент
                PAGE = 3            # страница сайта
                TUTORIAL = 4        # обучающий материал
                REVIEW = 5          # обзор
                RECIPE = 6          # рецепт
                RIGHTS = 7          # правовая информация
                */

                var cid = '';
                cid = route.path.substr(1);
                if (cid == 'blogs'){
                    this.pageType = 2;
                    document.title = 'Блог - Dia-status';

                } else if (cid == 'news'){
                    this.pageType = 1;
                    document.title = 'Новости - Dia-status';

                } else if (cid == 'tutorials'){
                    this.pageType = 4;
                    document.title = 'Обущающие материалы - Dia-status';

                } else if (cid == 'reviews'){
                    this.pageType = 5;
                    document.title = 'Обзоры - Dia-status';

                } else if (cid == 'recipes'){
                    this.pageType = 6;
                    document.title = 'Рецепты - Dia-status';

                } else if (cid == 'rights'){
                    this.pageType = 7;
                    document.title = 'Законы - Dia-status';

                } else {
                    console.log('Unknown path, get blogs');
                    this.pageType = 2;
                    document.title = 'Блог - Dia-status'
                }

                if (this.lastPageType != this.pageType){
                    // сменился тип страницы, сбраcываем пагинацию
                    //console.log('lpt = ', this.lastPageType, ' != ', this.pageType, ' = pt');
                    this.POF.set_page_size(20);
                    this.POF.set_item_count(0);
                    this.lastPageType = this.pageType;
                    this.POF.update_filters({
                        'language': this.lang_id,
                        'page_type': this.pageType,
                    });
                }

                this.loading = true;
                this.POF.send_query()
                .then(pages => {
                    this.pages = pages.results;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log('query exception', e);
                    this.loading = false;
                });

            },
            get_text(mnemonic) {
                return (this.txt[mnemonic] || {})[this.lang_id];
            },
        },

    }
</script>

<style>
  .item-wrapper {
    display: flex;
    align-items: center;
  }
  .v-application .headline, .v-application .title {
    word-break: break-word;
  }
  @media (max-width: 576px) {
    .item-wrapper {
      flex-direction: column;
    }
  }
</style>