<template>
<div class="editor-page-list col-12">

    <div class="row loading" v-if="loading">
        <div class="col-12 h-100">
            <div class="row backwhiteopacity"></div>
            <div class="row align-content-center h-100 py-5">
                <div class="col-12">
                    <div class="row justify-content-center">Loading...</div>
                </div>
                <div class="col-12">
                    <div class="row justify-content-center">
                        <i class="material-icons rotateinfinite">autorenew</i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content row" v-if="!loading">
    <v-container grid-list-xl v-if="page">
        <Editor :id_button="0"/>

        <v-layout row wrap>
            <v-flex xs10 offset-xs1>
                <v-card>
                    <v-flex>
                        <v-form ref="form" v-model="valid" lazy-validation v-if="page">
                            <br>
                            <h3>Редактор страницы</h3>
                            <br>
                            <v-text-field
                                label="ID"
                                v-if="page.id"
                                v-model="page.id"
                                readonly
                            ></v-text-field>

                            <v-text-field
                                v-model="page.name"
                                label="Имя (будет использоваться в url-е к страничке)"
                                placeholder="Имя"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="page.title"
                                label="Заголовок"
                                placeholder="Заголовок"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="page.sub_title"
                                label="Подзаголовок"
                                placeholder="Подзаголовок"
                            ></v-text-field>

                            <div class="row">
                                <div class="col-2">
                                    <v-img
                                        v-if="page.image"
                                        :src="page.image"
                                        label="Картинка"
                                        :href="`${page.image}`"
                                        width="100"
                                        height="100"
                                    >
                                    </v-img>
                                </div>
                                <div class="col-10">
                                    <input type="file" id="image" ref="image"/>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <label for="html_editor">HTML, richtext:</label>
                                    <ckeditor
                                        id="html_editor"
                                        :editor="editor"
                                        tag-name="textarea"
                                        v-model="page.html"
                                        :config="editorConfig"
                                        :placeholder="get_text('text')"
                                        class="form-control"
                                        rows="5"
                                        required
                                    >
                                    </ckeditor>
                                </div>
                            </div>

                            <!--
                            <div class="row">
                                <div class="col-6">
                                    <label for="html_editor">HTML, richtext:</label>
                                    <ckeditor
                                        id="html_editor"
                                        :editor="editor"
                                        tag-name="textarea"
                                        v-model="page.html"
                                        :config="editorConfig"
                                        :placeholder="get_text('text')"
                                        class="form-control"
                                        rows="5"
                                        required
                                    >
                                    </ckeditor>
                                </div>
                                <div class="col-6">
                                    <label for="html_text">HTML, raw:</label>
                                    <v-textarea
                                        id="html_text"
                                        v-model="page.html"
                                        placeholder="Введи html странички"
                                        auto-grow
                                        style="border:solid 1px black;"
                                        required
                                    ></v-textarea>
                                </div>
                            </div>
                            -->

                            <!--
                            <v-textarea
                                id="hidden_text"
                                v-model="page.hidden_text"
                                class="form-control"
                                label="Невидимый текст, SEO:"
                                placeholder="Невидимый текст"
                                style="border:solid 1px black;"
                                auto-grow
                            >
                            </v-textarea>
                            -->


                            <v-select
                                v-model="page.page_type"
                                :items="page_type_list"
                                label="Тип странички"
                                required
                                item-text="info"
                                item-value="id"
                            ></v-select>

                            <v-select
                                v-model="page.language"
                                :items="language_list"
                                label="Язык"
                                required
                                item-text="name"
                                item-value="id"
                            ></v-select>

                            Тэги:<br>
                            <div v-if="tag_list" class="row">
                                <div
                                    style="display: inline-block;"
                                    v-for="tag in tag_list"
                                    :key="tag.id"
                                >
                                    <v-btn
                                        color="green"
                                        v-if="tags.indexOf(tag.key) >= 0"
                                        v-on:click="remove_tag(tag.key)"
                                        style="background-color:green"
                                    >
                                        {{ tag.name }}
                                    </v-btn>

                                    <v-btn
                                        color="white"
                                        v-if="tags.indexOf(tag.key) === -1"
                                        v-on:click="add_tag(tag.key)"
                                    >
                                        {{ tag.name }}
                                    </v-btn>
                                </div>
                            </div>

                            <v-btn color="green"
                               @click="save(page)"
                            >
                                Сохранить
                            </v-btn>

                            <v-btn color="orange" @click="back()">
                                <v-icon left>arrow_back</v-icon>
                                Вернуться
                            </v-btn>

                            <v-btn color="red" @click="delete_page(page)" v-if="page.id">
                                <v-icon left>delete</v-icon>
                                Удалить
                            </v-btn>

                        </v-form>
                    </v-flex>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
    </div>

</div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import {create_formdata} from '../store/storage';
    import store from '../store';
    import Editor from "./Editor.vue"
    export default {
        name: "EditorPage",
        components:{Editor},

        computed: mapGetters('auth', [
            'isAuthenticated',
            'username',
            'user_id',
            'is_superuser',
            'is_author',
            'is_staff',
        ]),

        data() {
            return {
                page: null,
                profile: null,
                user: null,
                page_type_list: null,
                tag_list: null,
                tags: [],
                valid: false,
                editor: ClassicEditor,
                loading: false,
                editorConfig: {
                    'previewsInData': true,
                    'mediaEmbed': {
                        'previewsInData': true,

                        // about media embed config
                        // https://ckeditor.com/docs/ckeditor5/latest/features/media-embed.html
                        // https://iframely.com/docs/ckeditor
                        // https://github.com/ckeditor/ckeditor5/issues/2733
                    },
                },
            };
        },
        watch: {
            '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'];
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData(params = {}) {
                console.log('FETCH DATA');
                this.lang_id = store.getters['auth/lang_id'];
                this.txt = store.getters['summary/texts'];
                this.language_list = store.getters['summary/language_list'];
                this.page_type_list = store.getters['summary/page_type_list'];

                var params = this.$route.params;
                var cid = params.id;
                console.log('route params', params);
                console.log('cid', cid);

                var loadingcount = 2;
                this.loading = true;

                // get tag list
                var data = {
                    language: this.lang_id,
                };
                this.$store.dispatch({
                    type: 'tag/list',
                    data: data,
                })
                .then(tags => {
                    this.tag_list = tags.results;
                    console.log('tag_list: ', this.tag_list);
                    loadingcount--;
                    if (loadingcount == 0) {
                        this.loading = false;
                    }
                });

                if (cid === 'new') {
                    console.log('New page');
                    this.page = {
                        id: false,
                        page_type: 1,
                        language: 1,
                        user: this.user_id,
                    };
                    loadingcount--;
                    if (loadingcount == 0) {
                        this.loading = false;
                    }

                } else {

                    this.$store.dispatch({
                        type: 'page/get',
                        id: cid,
                    })
                    .then(page => {
                        this.page = page;
                        if (this.page.user != this.user_id && !this.is_superuser){
                            console.log('Cant modify not your page!');
                            this.$router.push('/editor/page/');
                        }
                        this.tags = this.page.tags.split(",")
                        console.log('tags: ', this.tags);
                        loadingcount--;
                        if (loadingcount == 0) {
                            this.loading = false;
                        }
                    });
                }
            },

            get_text(mnemonic) {
                return (this.txt[mnemonic] || {})[this.lang_id];
            },
            save(page) {
                this.page.tags = this.tags;

                delete page.image;
                let im = this.$refs.image.files[0];
                if (im) {
                    page.image = im;
                }

                // после того как сохранили надо переработать
                page.need_work = true;

                // обработанную страницу не трогаем
                delete page.page_done;

                if (page.id) {
                    // уже есть ИД, сохраняем
                    delete page.user;
                    let formData = create_formdata(page);

                    console.log('Have ID, save', formData);
                    this.loading = true;
                    this.$store.dispatch({
                        type: 'page/update',
                        id: page.id,
                        data: formData,
                        self: this,
                    })
                    .then(page => {
                        if (page) {
                            this.page = page;
                            this.tags = this.page.tags.split(",")
                            console.log('saved: ', this.page);
                        }
                        this.loading = false;
                    });

                } else {

                    // еще нет ИДа, заводим новый
                    let formData = create_formdata(page);

                    console.log('Have not ID, create', formData);
                    this.loading = true;
                    this.$store.dispatch({
                        type: 'page/create',
                        data: formData,
                        self: this,
                    })
                    .then(page => {
                        if (page) {
                            this.page = page;
                            this.tags = this.page.tags.split(",")
                            console.log('created: ', this.page);
                            this.$router.push('/editor/page/' + page.id);
                        }
                        this.loading = false;
                    });
                }
            },
            back() {
                this.$router.push('/editor/page');
            },
            delete_page(page) {
                console.log('start delete page');
                this.loading = true;
                this.$store.dispatch({
                    type: 'page/delete',
                    data: page,
                    self: this,
                })
                .then(page => {
                    this.$router.push('/editor/page');
                    this.loading = false;
                });
            },
            remove_tag(value) {
                this.tags = this.tags.filter(function(ele){
                    return ele != value;
                });
                console.log('remove tag', value, this.tags);
            },
            add_tag(value) {
                if(this.tags.indexOf(value) === -1) {
                    this.tags.push(value);
                };
                console.log('add tag', value, this.tags);
            },
        },
    }
</script>

<style scoped>

</style>