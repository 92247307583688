import Vue from 'vue';
import moment from 'moment';

Vue.filter('DateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss');
    }
});

Vue.filter('Date', function(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY');
    }
});

Vue.filter('Time', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm:ss');
    }
});
Vue.filter('filename', function (value) {
    if (value) {
        if (typeof value === 'object' || value instanceof Object) {
            value = value.name;
        }
        let pos = value.lastIndexOf("/");
        if (pos && pos + 1 < value.length) {
            return decodeURIComponent(value.substring(pos + 1, value.length));
        }
    }
    return null;
});