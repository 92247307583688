import Vue from 'vue';
import Router from 'vue-router';

import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Lost from '../views/Lost.vue';
import BJE from '../views/BJE.vue';

import Register from '../views/Register.vue'
import PasswordReset from '../views/PasswordReset.vue'

import Page from '../components/Page.vue'
import PagesList from '../components/PagesList.vue'

import EditorPage from '../components/EditorPage.vue'
import EditorPageList from '../components/EditorPageList.vue'
import EditorMedia from '../components/EditorMedia.vue'
import EditorNavbar from '../components/EditorNavbar.vue'

import store from '../store';

// требуется быть авторизованным
// вызываем ини модуля аутентификации, если тот находит токен - все ок
// если же нет, отправляет на страницу логина
const requireAuthenticated = (to, from, next) => {
    console.log('requireAuthenticated');
    store.dispatch('auth/initialize')
    .then(() => {
        if (!store.getters['auth/isAuthenticated']) {
            // давай аусвайс, жЫвотное!
            next('/login');
        } else {
            store.dispatch('summary/initialize')
            .then(() => {
                if (!store.getters['summary/texts']) {
                    // если данных нет - запрашиваем их
                    store.dispatch('summary/get')
                    .then(() => {
                        next();
                    });
                } else {
                    next();
                }
            });

            next();
        }
    });
};


// требуется быть не авторизованным
// если авторизован - выкидываем в /blogs
const requireUnauthenticated = (to, from, next) => {
    console.log('requireUnauthenticated');
    store.dispatch('auth/initialize')
    .then(() => {
        if (store.getters['auth/isAuthenticated']) {
            next('/blogs');
        } else {
            next();
        }
    });
};

//
const initAuth = (to, from, next) => {
    console.log('initAuth');
    store.dispatch('auth/initialize')
    .then(() => {
        if (store.getters['auth/isAuthenticated']) {
            console.log('logged in!');
        }
        next();
    });
};

//
const requireAuthor = (to, from, next) => {
    console.log('requireAuthor');
    store.dispatch('auth/initialize')
    .then(() => {
        if (store.getters['auth/isAuthenticated'] && store.getters['auth/is_author']) {
            next();
        } else {
            next('/blogs');
        }
    });
};

//
const requireSuperuser = (to, from, next) => {
    console.log('requireSuperUser');
    store.dispatch('auth/initialize')
    .then(() => {
        if (store.getters['auth/is_superuser']) {
            next();
        } else {
            next('/blogs');
        }
    });
};

// при логауте редиректим на страницу логина
const redirectLogout = (to, from, next) => {
    console.log('redirectLogout');
    store.dispatch('auth/logout')
    .then(() => {
        next('/login');
    });
};

Vue.use(Router);

export default new Router({
    saveScrollPosition: true,
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/blogs',
        },

        {
            path: '/admin',
            beforeEnter() {
                location.href = 'http://' + window.location.hostname + '/admin/';
            },
        },

        {
            path: '/login',
            component: Login,
            beforeEnter: requireUnauthenticated,
        },
        {
            path: '/logout',
            beforeEnter: redirectLogout,
        },
        {
            path: '/register',
            component: Register,
            beforeEnter: requireUnauthenticated,
        },
        {
            path: '/reset',
            component: PasswordReset,
            beforeEnter: requireUnauthenticated,
        },

        {
            path: '/editor/media',
            component: EditorMedia,
            beforeEnter: requireSuperuser,
        },
        {
            path: '/editor/navbar',
            component: EditorNavbar,
            beforeEnter: requireSuperuser,

        },
        {
            path: '/editor/page',
            component: EditorPageList,
            beforeEnter: requireAuthor,
        },
        {
            path: '/editor/page/:id?',
            component: EditorPage,
            beforeEnter: requireAuthor,
        },


        {
            path: '/bje',
            component: BJE,
            beforeEnter: initAuth,
        },



        {
            path: '/news',
            component: PagesList,
            beforeEnter: initAuth,
        },
        {
            path: '/blogs',
            component: PagesList,
            beforeEnter: initAuth,
        },
        {
            path: '/tutorials',
            component: PagesList,
            beforeEnter: initAuth,
        },
        {
            path: '/reviews',
            component: PagesList,
            beforeEnter: initAuth,
        },
        {
            path: '/recipes',
            component: PagesList,
            beforeEnter: initAuth,
        },
        {
            path: '/rights',
            component: PagesList,
            beforeEnter: initAuth,
        },

        {
            path: '/article/*',
            component: Page,
            beforeEnter: initAuth,
        },

        {
            path: '*',
            component: Lost,
            beforeEnter: initAuth,
        },
    ],
});
