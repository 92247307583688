<template>
    <div class="col-12 py-0 wrapper">

    <div class="row wrap navbar-def">
        <div class="col-12 py-0">
            <v-toolbar height="auto" text color=info class="main-menu">
                <div v-if="is_superuser" class="text-white">
                    VERSION: {{version}}
                </div>

                <v-spacer></v-spacer>

                <v-toolbar-items v-for="item in nav" :key="item.id">
                    <v-btn
                        v-if="item.visibility===1 || (item.visibility===2 && isAuthenticated) || (item.visibility===3 && !isAuthenticated)"
                        :to="item.url"
                        color="black"
                        text
                        rounded
                    >
                        {{item.name}}
                    </v-btn>
                </v-toolbar-items>

                <v-toolbar-items v-if="isAuthenticated">
                    <v-btn
                        to="/bje"
                        color="black"
                        text
                        rounded
                    >
                        БЖЕ
                    </v-btn>
                </v-toolbar-items>

                <v-spacer></v-spacer>

                <v-toolbar-items v-if="isAuthenticated || username || is_superuser || is_staff" class="logged-in">
                    <v-btn
                        v-if="username"
                        to="/profile"
                        disabled
                        color="black"
                        text
                        rounded
                    >
                        {{get_text('logged_in')}} {{ username }}
                    </v-btn>

                    <v-btn
                        to="/admin"
                        v-if="is_superuser || is_staff"
                        color="black"
                        text
                        rounded
                    >
                        admin
                    </v-btn>

                    <v-btn
                        to="/editor/page"
                        v-if="is_superuser || is_staff || is_author"
                        color="black"
                        text
                        rounded
                    >
                        {{get_text('editor')}}
                    </v-btn>

                    <v-btn
                        to="/logout"
                        v-if="isAuthenticated"
                        color="black"
                        text
                        rounded
                    >
                        {{get_text('logout')}}
                    </v-btn>

                    <!--
                    <v-btn text
                        to="/register"
                        v-if="!isAuthenticated"
                        color="black"
                        text
                        rounded
                    >
                        {{get_text('registration')}}
                    </v-btn>
                    <v-btn text
                        to="/login"
                        v-if="!isAuthenticated"
                        color="black"
                        text
                        rounded
                    >
                        {{get_text('login')}}
                    </v-btn>
                    -->

                    

                </v-toolbar-items>

            </v-toolbar>
        </div>
    </div>

    </div>
</template>

<script>
    import store from '../store';
    import {mapGetters} from 'vuex';

    export default {
        name: 'navbar',

        computed: mapGetters('auth', [
            'isAuthenticated',
            'username',
            'is_superuser',
            'is_author',
            'is_staff',
        ]),

        data() {
            return {
                inputdata: null,
                orderingBy: null,
                paginationTotal: null,
                paginateBy: null,
                currPage: 1,
                nextPage: null,
                prevPage: null,
                chosen: null,
                txt: null,
                lang_id: null,
                path: null,
                version: null,
                nav: null,
                all: true,
                visibil: {
                    1: 'all',
                    2: 'isAuthenticated',
                    3: '!isAuthenticated'
                }
            };
        },

        watch: {
            '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'] || 1;
                this.fetchData();
            },
            $route() {
                this.path = this.$route.path.substring(1);
            }
        },

        methods: {
            fetchChosen() {
                this.lang_id = store.getters['auth/lang_id'] || 1;
            },
            fetchData() {
                this.lang_id = store.getters['auth/lang_id'] || 1;
                this.txt = store.getters['summary/texts'];
                this.version = store.getters['summary/version'];
                console.log("LANGUAGE ID: ", this.lang_id);
                let data = {"language": this.lang_id};
                this.$store.dispatch({
                    type: 'navbar/list',
                    data: data,
                })
                .then(navbar => {
                    this.nav = navbar.results;
                });
                this.path = this.$route.path.substring(1);
            },
            get_text(mnemonic) {
                if (!this.txt) {
                    return;
                }
                return (this.txt[mnemonic] || {})[this.lang_id];
            },
        },
        created() {
            // fetch the data when the view is created and the data is already being observed
            this.fetchData();
        },
    };
</script>

<style>
    .navbar-def {
        border-top: 1px solid #a7e565 !important;
    }
    .navbar-def .v-btn__content {
        color: white !important;
    }

    .v-toolbar__content .v-toolbar__items {
        height: 48px;
    }

    @media (max-width: 768px) {
      .v-toolbar__content {
        display: flex;
        flex-direction: column;
      }

      .v-toolbar__content > *, .v-toolbar__content a {
        width: 100%;
      }

      .wrapper {
        padding: 0!important;
      }

      .logged-in > a {
        width: auto;
      }

      .logged-in {
        justify-content: space-around;
      }
    }


</style>
