<template>
    <v-toolbar dense text class="row justify-content-center">
        <v-btn-toggle v-model="id_button" fab>
            <v-btn
                text
                rounded
                to="/editor/page"
                v-if="is_superuser || is_staff || is_author"
            >
                {{get_text('editor_page')}}
            </v-btn>

            <v-btn
                text
                rounded
                to="/editor/media"
                v-if="is_superuser || is_staff"
            >
                {{get_text('editor_media')}}
            </v-btn>

            <v-btn
                text
                rounded
                to="/editor/navbar"
                v-if="is_superuser || is_staff"
            >
                {{get_text('editor_navbar')}}
            </v-btn>
        </v-btn-toggle>
    </v-toolbar>
</template>

<script>
    import {mapGetters, mapActions, mapState} from 'vuex';
    import store from '../store';

    export default {
        name: "Editor",
        props: ['id_button'],

        computed: mapGetters('auth', [
            'isAuthenticated',
            'username',
            'is_superuser',
            'is_author',
            'is_staff',
        ]),

        data() {
            return {
                txt: null,
                lang_id: null,
            };
        },
        watch: {
            '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'];
            }
        },
        created() {
            this.fetchData()
        },
        methods: {
            fetchData(params = {}) {
                this.lang_id = store.getters['auth/lang_id'];
                this.user_id = store.getters['auth/user_id'];
                this.txt = store.getters['summary/texts'];
                document.title = 'Редактор - Dia-status';
            },
            get_text(mnemonic) {
                return (this.txt[mnemonic] || {})[this.lang_id];
            },
        }
    }
</script>

<style scoped>

</style>