import Vue from 'vue';
import App from './App.vue';
import router from './router'
import store from './store';
import vueHeadful from 'vue-headful';
import './filters/index.js';
import 'vue-search-select/dist/VueSearchSelect.css';

Vue.component('vue-headful', vueHeadful);

window.$ = window.jQuery = require('jquery');

//Vue.config.productionTip = false;

// VUETIFY
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/es5/util/colors';
import 'bootstrap/scss/bootstrap.scss';

// https://vuetifyjs.com/en/features/icons/#material-design-icons
import 'font-awesome/css/font-awesome.css';                                     // iconfont: 'fa4'
import '@fortawesome/fontawesome-free/css/all.css';                             // iconfont: 'fa'
import '@mdi/font/css/materialdesignicons.css'                                  // iconfont: 'mdi'
import { mdiAccount } from '@mdi/js';                                           // svgPath: mdiAccount
import 'material-design-icons-iconfont/dist/material-design-icons.css';         // iconfont: 'md',
import 'webpack-material-design-icons/material-design-icons.css';

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.use(Vuetify, {
    theme: {
        primary: colors.red.darken1, // #E53935
        secondary: colors.red.lighten4, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
        info: '#73b727',
    }
});

//Vue.use(Vuetify);

// rich text editor
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );

let vuetify = new Vuetify({
    icons: {
        //iconfont: 'md',
        //iconfont: 'mdiSvg',
        iconfont: 'fa4',
    },
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
})
.$mount('#app')

