<template>
    <div class="col-12">
        <Editor :id_button="1"/>

        <v-dialog v-model="dialog" max-width="500px" v-if="dialog">
            <v-card>
                <v-card-title>
                    <span class="headline"> Редактор медиа </span>
                </v-card-title>

                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="change_media.name"
                                    label="Имя файла"
                                >
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="change_media.media_file"
                                    label="Урл на файл"
                                    readonly
                                    v-if="!dialog_new"
                                >
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <input type="file" id="media_file" ref="media_file"/>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" flat @click="close_dialog()">Отменить</v-btn>
                    <v-btn color="blue darken-1" flat @click="save_dialog(change_media)">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="row">
            <v-btn color="orange" @click="add_media()">
                <v-icon left>add</v-icon>
                Добавить файл
            </v-btn>
        </div>

        <v-data-table
            v-if="medias"
            :headers="headers"
            :items="medias"
            hide-default-footer
            disable-pagination
            class="elevation-1 row fixed-header"
        >
            <template v-slot:item="{ item }">
                <tr>
                <td>{{ item.id }}</td>
                <td class="justify-center layout px-0">
                    <v-icon
                        color="blue"
                        class="mr-2"
                        @click="update_mediateka(item)"
                    >
                        edit
                    </v-icon>
                    <v-icon
                        color="red"
                        @click="delete_page(item)"
                    >
                        delete
                    </v-icon>
                </td>
                <td>
                    <a :href="item.media_file">
                        {{ item.name }}
                    </a>
                </td>
                <td style="word-wrap: break-word; word-break: break-all">
                    {{ item.media_file }}
                </td>
                </tr>
            </template>
        </v-data-table>

        <paginator></paginator>

    </div>
</template>

<script>
    import store from '../store';
    import {create_formdata} from '../store/storage';
    import Editor from "./Editor.vue";
    import {Notice} from "../store/notice";
    import {POF} from '../api/POF/POF';
    import paginator from '../api/POF/paginator.vue';
    import filters from '../api/POF/filters.vue';
    export default {
        name: "MediaEditor",
        components:{
            Editor,
            paginator,
            filters,
        },
        data() {
            return {
                medias: null,
                headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Действия', value: 'actions'},
                    {text: 'Имя файла', value: 'name'},
                    {text: 'Ссылка', value: 'media_file'},
                ],
                user: null,
                dialog: false,
                dialog_new: null,
                change_media: null,
                POF: new POF('mediafile', 'editor'),
            };
        },
        watch: {
            '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'];
            }
        },
        created() {
            this.fetchData()
        },
        methods: {
            fetchData(params = {}) {
                console.log('FETCH DATA');
                this.lang_id = store.getters['auth/lang_id'];
                this.user_id = store.getters['auth/user_id'];
                this.username = store.getters['auth/username'];
                this.txt = store.getters['summary/texts'];
                this.language_list = store.getters['summary/language_list'];

                this.loading = true;
                this.POF.send_query()
                .then(medias => {
                    this.medias = medias.results;
                    this.loading = false;
                    for (var media in this.medias) {
                        this.medias[media].media_file = decodeURIComponent(this.medias[media].media_file)
                    }
                })
                .catch((e) => {
                    console.log('query exception', e);
                    this.loading = false;
                });
            },
            get_text(mnemonic) {
                return (this.txt[mnemonic] || {})[this.lang_id];
            },
            delete_page(page) {
                let conf = confirm(this.get_text('are_you_sure') + ' ' + page.name + '?');
                if (conf === false) {
                    return;
                }

                this.$store.dispatch({
                    type: 'mediafile/delete',
                    data: page,
                    self: this,
                }).then(page => {
                    this.fetchData();
                });
            },
            add_media() {
                this.change_media = {};
                this.dialog_new = true;
                this.dialog = true;
            },
            update_mediateka(med) {
                this.change_media = med;
                this.dialog_new = false;
                this.dialog = true;
            },
            close_dialog() {
                this.dialog = false;
            },
            save_dialog(med) {
                let mf = this.$refs.media_file.files[0];
                if (this.dialog_new) {
                    med.media_file = mf;
                    this.$store.dispatch({
                        type: 'mediafile/create',
                        data: create_formdata(med),
                        self: this,
                    })
                    .then(media => {
                        this.dialog = false;
                        this.fetchData();
                    })
                } else {
                    if (mf && med.name) {
                        med.media_file = mf;
                        this.$store.dispatch({
                            type: 'mediafile/update',
                            id: med.id,
                            data: create_formdata(med),
                            self: this,
                        })
                        .then(media => {
                            this.dialog = false;
                            this.fetchData();
                        });
                    } else {
                        let messg = "Необходимо заново добавить медиафайл и ввести имя";
                        let error = "  No mediafile and file name entered!";
                        Notice.error(
                            messg,
                            error,
                            this,
                        );
                    }
                }
            }
        }
    }
</script>

<style>
table {
    width: 100%;
}
</style>