import API from '../api';

export function set_item(key, value){
    //console.log('STORAGE SET ', key, value);
    localStorage.setItem(key, JSON.stringify(value));
    return 0;
};

export function get_item(key){
    //
    const val = localStorage.getItem(key);
    let parsed = null;
    try {
        parsed = JSON.parse(val);
    }
    catch(error) {
        console.log('STORAGE GET ERROR! ', key, error);
    };
    return parsed;
};

export function del_item(key){
    localStorage.removeItem(key);
};

export function is_empty(data){
    if(typeof(data) == 'number' || typeof(data) == 'boolean'){
        return false;
    }
    if(typeof(data) == 'undefined' || data === null){
        return true;
    }
    if(typeof(data.length) != 'undefined'){
        return data.length == 0;
    }
    var count = 0;
    for(var i in data){
        if(data.hasOwnProperty(i)){
            count ++;
        }
    }
    return count == 0;
}

// paginate-order-filter class
export class POF {
    constructor(model, suffix='') {
        this.model = model;
        this.storage_key = model.toUpperCase() + '_' + suffix + '_POF_Storage_Key';
        //console.log('Init POF', this.model);

        this.init();
        this.load_data_from_storage();
    };

    init(){
        //console.log('init from zero');
        this.labels = {};
        this.filters = {};
        this.filter_fields = {};
        this.filter_field_names = ["f1", "f2", "f3", "f4"];
        this.query_params = {};
        this.item_count = 0;
        this.pages_count = 0;
        this.fixed_page_size = false;
        this.fixed_page_step = false;
        this.dont_save_params = false;
        this.update_order('id', 'desc');
        this.set_page_size(20);
        this.update_labels({
            'page': 'Страница',
            'go': 'Перейти',
            'page_size': 'Размер страницы',
        });
    }

    // ---------------------------- storage ------------------------------------
    // load options from storage or init from zero
    load_data_from_storage(){
        if (this.dont_save_params){
            return
        }
        //console.log('load_data_from_storage', this.storage_key);

        // try load saved data
        const data = get_item(this.storage_key);
        if (data) {
            // loaded
            //console.log('loaded!');
            Object.entries(data).forEach(entry => {
                const [key, value] = entry;
                this[key] = value;
            });
        }
        return;
    }

    // save all options to storage
    save_data_to_storage(){
        if (this.dont_save_params){
            return
        }
        const data = {
            'filters': this.filters,
            'labels': this.labels,
            'item_count': this.item_count,
            'pages_count': this.pages_count,
            'page_size': this.page_size,
            'page': this.page,
            'order_field': this.order_field,
            'order_direction': this.order_direction,
            'order': this.order,
        }
        set_item(this.storage_key, data);
        //console.log('save_data_to_storage', this.storage_key, data);
        return
    }

    //------------------------------- labels -----------------------------------
    update_label(key, val){
        this.labels[key] = val;
        return;
    }
    update_labels(labels){
        Object.entries(labels).forEach(entry => {
            const [key, value] = entry;
            this.update_label(key, value);
        });
        return;
    }

    //-------------------------------- pages -----------------------------------
    set_page_size(page_size=20){
        if (page_size > 0){
            this.page_size = page_size;
            this.page = 1;
        }
        return;
    }

    // set item count and recount pages count
    set_item_count(item_count){
        this.item_count = item_count;
        this.pages_count = (this.item_count / this.page_size >> 0);
        if (this.item_count % this.page_size > 0){
            this.pages_count = this.pages_count + 1;
        }
        if (this.page > this.pages_count){
            this.page = this.pages_count;
        }
        return;
    }

    //
    goto_page(page=1){
        if (page < 1){
            page = 1;
        }
        if (page > this.pages_count){
            page = Math.max(this.pages_count, 1);
        }
        this.page = page;
        return;
    }

    //
    next_page(){
        var np = this.page + 1;
        return this.goto_page(np);
    }

    //
    prev_page(){
        var np = this.page - 1;
        return this.goto_page(np);
    }

    //-------------------------------- order -----------------------------------
    update_order(field='id', direction='desc'){
        if (direction != 'asc'){
            direction = 'desc';
        }
        this.order_field = field;
        this.order_direction = direction;
        this.recount_order();
        return;
    }

    //
    recount_order(){
        var old_order = this.order;
        this.direction = {
            'asc': '+',
            'desc': '-',
        }[this.order_direction];
        this.order = this.direction + this.order_field;
        if (old_order != this.order){
            this.page = 1;
        }
        return;
    }

    // добавить в интерфейс поле сортировки
    /*
    {
        'label': 'Сортировка',
        'value': {
            'field':  this.POF.order_field,
            'direction': this.POF.order_direction,
        },
        'choice_list': [
            {
                'label': 'id, по убыванию',
                'value': {
                    'field': 'id',
                    'direction': 'desc',
                },
            },
            {
                'label': 'id, по возрастанию',
                'value': {
                    'field': 'id',
                    'direction': 'asc',
                },
            },
            {
                'label': 'Cоздано, по убыванию',
                'value': {
                    'field': 'create_time',
                    'direction': 'desc',
                },
            },
            {
                'label': 'Cоздано, по возрастанию',
                'value': {
                    'field': 'create_time',
                    'direction': 'asc',
                },
            },
            {
                'label': 'Изменено, по убыванию',
                'value': {
                    'field': 'update_time',
                    'direction': 'desc',
                },
            },
            {
                'label': 'Изменено, по возрастанию',
                'value': {
                    'field': 'update_time',
                    'direction': 'asc',
                },
            },
        ],
    }
    */
    update_order_field(value){

        if (typeof value != 'object'){
            console.log('Bad order obj, empty');
            return;
        }

        value['type'] = 'order';

        // bootstrap grids. sum(width) need be <= 12
        var w = value['width']
        if (w && Number.isFinite(w)){
            // to int
            w = w | 0;
            if (w < 2 || w > 6){
                console.log('Bad width of order, allow 2 - 6');
                return;
            }
            value['width'] = w;
        } else{
            // default 2
            value['width'] = 2;
        }

        var val = value['value'];
        if ((!val) || typeof(val) != 'object'){
            console.log('Bad order obj, have not value');
            return;
        }
        var f = val['field'];
        if ((!f) || typeof(f) != 'string'){
            console.log('Bad order obj, have not field in value');
            return;
        }
        var d = val['direction'];
        if ((!d) || typeof(d) != 'string'){
            console.log('Bad order obj, have not direction in value');
            return;
        }

        var lst = value['choice_list']
        if ((!lst) || Array.isArray(lst)){
            // у каждого варианта дожны быть поля value, label
            for (var index = 0; index < lst.length; ++index) {
                var choice = lst[index];
                var val = choice['value'];
                if ((!val) || typeof(val) != 'object'){
                    console.log('Bad order obj, have not value for choice element');
                    return;
                }
                var f = val['field'];
                if ((!f) || typeof(f) != 'string'){
                    console.log('Bad order obj, have not field in value for choice element');
                    return;
                }
                var d = val['direction'];
                if ((!d) || typeof(d) != 'string'){
                    console.log('Bad order obj, have not direction in value for choice element');
                    return;
                }
                if (!('label' in choice)){
                    console.log('Bad order obj, have not label for choice element');
                    return;
                }
            };
        }

        // если все ок добавляем сортировку в интерфейс
        this.filter_fields['order'] = value;
        return;
    }

    //--------------------------------- filters --------------------------------
    //
    update_filter(key, value){
        console.log('update_filter', key, value);
        var old_val = this.filters[key];
        if (! is_empty(value)){
            //console.log('set');
            this.filters[key] = value;
        } else {
            if (key in this.filters){
                //console.log('del');
                delete this.filters[key];
            }
        }
        if (old_val != this.filters[key]){
            this.page = 1;
        }
        return;
    }

    //
    update_filters(filters={}){
        Object.entries(filters).forEach(entry => {
            const [key, value] = entry;
            this.update_filter(key, value);
        });
        return;
    }

    // добавить в интерфейс поле фильтрации
    /* example
    {
        'f1': {
            'type': 'choice',
            'label': 'Язык',
            'hint': 'Язык материала',
            'name': 'language',
            'value': this.POF.filters.language || null,
            'width': 2,
            'choice_list': [
                {
                    'value': null,
                    'label': 'Все языки',
                },
                {
                    'value': 1,
                    'label': 'Русский',
                },
                {
                    'value': 2,
                    'label': 'English',
                },
            ],
        },
        'f2': {
            'type': 'choice',
            'label': 'Тип',
            'hint': 'Тип материала',
            'name': 'page_type',
            'value': this.POF.filters.page_type || null,
            'width': 2,
            'choice_list': [
                {
                    'value': null,
                    'label': 'Все типы',
                },
                {
                    'value': 1,
                    'label': 'Новость',
                },
                {
                    'value': 2,
                    'label': 'Блог',
                },
                {
                    'value': 3,
                    'label': 'Страница',
                },
                {
                    'value': 4,
                    'label': 'Обучение',
                },
                {
                    'value': 5,
                    'label': 'Обзор',
                },
            ],
        },
        'f3': {
            'type': 'interval',
            'label_from': 'ИД, от',
            'label_to': 'ИД, до',
            'hint': 'Интервал ИД',
            'name_from': 'id_from',
            'name_to': 'id_to',
            'value_from': this.POF.filters.value_from || null,
            'value_to': this.POF.filters.value_to || null,
            'width': 3,
        },
        'f4': {
            'type': 'search',
            'label': 'Поиск',
            'hint': 'Поиск',
            'name': 'search',
            'value': this.POF.filters.value || null,
            'width': 2,
        },
    }
    */

    // update 1 filter field
    // keys f1 - f4
    update_filter_field(key, value){
        console.log('update_filter_field', key, value, typeof value);

        var inc = this.filter_field_names.includes(key);
        if (!inc){
            console.log('Bad filter name, allow f1 - f4');
            return;
        }
        if (typeof value != 'object'){
            console.log('Bad filter obj, empty');
            return;
        }

        // bootstrap grids. sum(width) need be <= 12
        var w = value['width']
        if (w && Number.isFinite(w)){
            // to int
            w = w | 0;
            if (w < 2 || w > 6){
                console.log('Bad width of filter, allow 2 - 6');
                return;
            }
            value['width'] = w;
        } else{
            // default 2
            value['width'] = 2;
        }

        // 
        if (!('type' in value)){
            console.log('Bad filter obj, have not type');
            return;
        }
        var typ = value['type'];
        console.log('type:', typ);

        switch(typ) {

            case 'number':
                if (!('min' in value)){
                    console.log('Bad filter obj, have not min for number type');
                    return;
                }
                if (!('max' in value)){
                    console.log('Bad filter obj, have not max for number type');
                    return;
                }
                if (!('name' in value)){
                    console.log('Bad filter obj, have not name');
                    return;
                }
                if (!('value' in value)){
                    console.log('Bad filter obj, have not value');
                    return;
                }
                break;

            case 'choice':
                if (!('name' in value)){
                    console.log('Bad filter obj, have not name');
                    return;
                }
                if (!('value' in value)){
                    console.log('Bad filter obj, have not value');
                    return;
                }
                if (!('choice_list' in value)){
                    console.log('Bad filter obj, have not choice_list for choice type');
                    return;
                }
                var clist = value['choice_list'];
                if (!(Array.isArray(clist))){
                    console.log('Bad filter obj, choice_list is not array');
                    return;
                }
                // у каждого варианта дожны быть поля value, label
                for (var index = 0; index < clist.length; ++index) {
                    var choice = clist[index];
                    if (!('value' in choice)){
                        console.log('Bad filter obj, have not value for choice element');
                        return;
                    }
                    if (!('label' in choice)){
                        console.log('Bad filter obj, have not label for choice element');
                        return;
                    }
                };
                break;

            case 'interval':
                if (!('name_from' in value)){
                    console.log('Bad filter obj, have not name_from');
                    return;
                }
                if (!('name_to' in value)){
                    console.log('Bad filter obj, have not name_to');
                    return;
                }
                if (!('value_from' in value)){
                    console.log('Bad filter obj, have not value_from');
                    return;
                }
                if (!('value_to' in value)){
                    console.log('Bad filter obj, have not value_to');
                    return;
                }
                break;

            case 'search':
                if (!('value' in value)){
                    console.log('Bad filter obj, have not value');
                    return;
                }
                break;

            default:
                console.log('Bad filter value, incorrect type');
                return;
        }

        // если все ок добавляем фильтр в интерфейс
        this.filter_fields[key] = value;
        return;
    }

    //
    update_filter_fields(filters={}){
        Object.entries(filters).forEach(entry => {
            const [key, value] = entry;
            this.update_filter_field(key, value);
        });
        return;
    }


    //--------------------------------- query ----------------------------------
    //
    make_query_params(){
        this.recount_order();
        if (this.page < 1 || this.page > this.pages_count){
            this.page = 1;
        }
        this.query_params = {
            ordering: this.order,
            page: this.page,
            limit: this.page_size,
        };
        Object.entries(this.filters).forEach(entry => {
            const [key, value] = entry;
            this.query_params[key] = value;
        });
        return this.query_params;
    }

    //
    send_query(){
        //console.log('send query');

        //
        this.make_query_params();

        //
        this.save_data_to_storage();

        self = this;
        // https://learn.javascript.ru/promise
        return new Promise(function(resolve, reject) {
            var model = self.model;
            var data = self.query_params;
            var api = API[model];

            console.log(model, api);
            api.list(data)
            .then(datas => {
                //console.log('query ok');
                self.set_item_count(datas.count);
                resolve(datas);
            })
            .catch((e) => {
                console.log('query exception', e);
                reject(e);
            });
        });
    }

}



// Author - Martynov Sergey (writemehere@bk.ru)