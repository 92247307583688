import API from '../api/api';
import router from '../router';
import {get_item, set_item, del_item,} from './storage';
import {Notice} from "./notice";
import {
    PAGE_OK,
    PAGE_ERROR,
    PAGES_SET,
    PAGE_SET,
    PAGE_ADD,
    PAGE_DEL,
} from './types';

const PAGE_STORAGE_KEY = 'PAGE_STORAGE_KEY';
const PAGES_STORAGE_KEY = 'PAGES_STORAGE_KEY';

const initialState = {
    error: false,
    page: null,
    pages: null,
};

const getters = {
    pages: function (state) {
        return state.pages;
    },
    page: state => state.page,
};

const mutations = {
    [PAGE_OK](state) {
        console.log('PAGE_OK')
        state.error = false;
    },
    [PAGE_ERROR](state, do_logout = false) {
        console.log('PAGE_ERROR')
        state.error = true;
        if (do_logout) {
            router.push('/logout');
        }
    },
    [PAGES_SET](state, pages) {
        console.log('PAGES_SET ', pages)
        state.error = false;
        set_item(PAGES_STORAGE_KEY, pages);
        state.pages = pages;
    },
    [PAGE_SET](state, page) {
        state.error = false;
        set_item(PAGE_STORAGE_KEY, page);
        state.page = page;
    },
    [PAGE_ADD](state, page) {
        console.log('PAGE_ADD ', page)
        state.error = false;
        state.pages = [page, ...state.pages]
    },
    [PAGE_DEL](state, {id}) {
        console.log('PAGE_DEL ');
        state.error = false;
        del_item(PAGE_STORAGE_KEY);
        state.page = null;
        if (state.pages.filter) {
            state.pages = state.pages.filter(
                page => {
                    return page.id !== id
                }
            );
        }
    },
};


const actions = {
    list({commit}, {data}) {
        console.log('store page list data=', data);
        return API.page.list(data)
            .then(function (data) {
                commit(PAGES_SET, data);
                return data
            })
            .catch(function (error) {
                console.log('error: ', error);
                commit(PAGE_ERROR);
                //return {'count': 0,};
                throw error;
            });
    },
    get({commit}, {id}) {
        console.log('store page get, ', id);
        return API.page.get(id)
            .then(function (data) {
                commit(PAGE_SET, data);
                return data;
            })
            .catch(function (error) {
                console.log('error: ', error);
                commit(PAGE_ERROR);
            });
    },
    update({commit}, {id, data, self}) {
        console.log('store page update, ', id, data, self);
        return API.page.update(id, data)
            .then(function (data) {
                let messg = self.get_text('page') + ' - ' + self.get_text('update_success');
                console.log('store page update, ', self);
                Notice.success(
                    messg,
                    self
                );
                commit(PAGE_SET, data);
                return data;
            })
            .catch(function (error) {
                let messg = self.get_text('page') + ' - ' + self.get_text('update_error');
                console.log('error: ', error);
                Notice.error(
                    messg,
                    error,
                    self,
                );
                commit(PAGE_ERROR);
            });
    },
    create({commit}, {data, self}) {
        console.log('store page create, ', data);
        return API.page.create(data)
            .then(function (data) {
                let messg = self.get_text('page') + ' - ' + self.get_text('add_success');
                console.log('store page create, ', self);
                Notice.success(
                    messg,
                    self
                );
                commit(PAGE_SET, data);
                return data;
            })
            .catch(function (error) {
                let messg = self.get_text('page') + ' - ' + self.get_text('add_error');
                console.log('error: ', error);
                Notice.error(
                    messg,
                    error,
                    self
                );
                commit(PAGE_ERROR);

            });
    },
    delete({commit}, {data, self}) {
        console.log('store page delete, ', data, self);
        return API.page.delete(data)
            .then(function (data) {
                let messg = self.get_text('page') + ' - ' + self.get_text('delete_success');
                Notice.success(
                    messg,
                    self
                );
                commit(PAGE_DEL,data);
                return data;
            })
            .catch(function (error) {
                let messg = self.get_text('page') + ' - ' + self.get_text('delete_error');
                console.log('error: ', error);
                Notice.error(
                    messg,
                    error,
                    self
                );
                commit(PAGE_ERROR);
            });
    },
    search({commit}, {data}) {
        console.log('search=', data);
        return API.search.list(data)
            .then(function (data) {
                return data
            })
            .catch(function (error) {
                console.log('error: ', error);
                commit(PAGE_ERROR);
            });
    },



    // нач состояние. Смотрим, если в хранилище есть - просовывыаем
    initialize({commit}) {
        console.log('store page init');
        const page = get_item(PAGE_STORAGE_KEY);
        const pages = get_item(PAGES_STORAGE_KEY);

        if (page) {
            commit(PAGE_SET, page);
        } else {
            commit(PAGE_SET, null);
        }
        if (pages) {
            commit(PAGES_SET, pages);
        } else {
            commit(PAGES_SET, null);
        }
    },
};


export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
