import session from './session';

export default {
    login(username, password) {
        console.log('api login');

        return session.post(
            '/core/api/login/',
            {
                'username': username,
                'password': password,
            },
            {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                }
            }
        )
    },

    change_password(username, password, new_password){
        console.log('Change password');
        return session.post(
            '/core/api/changepassword/',
            {
                'username': username,
                'password': password,
                'new_password': new_password,
            },
            {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                }
            }
        )
    },


    logout() {
        return session.post('/core/api/logout/', {});
    },
};
