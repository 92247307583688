import API from '../api/api';
import router from '../router';
import {get_item, set_item, del_item,} from './storage';
import {Notice} from "./notice";
import {
    NAVBAR_OK,
    NAVBAR_ERROR,
    NAVBARS_SET,
    NAVBAR_SET,
    NAVBAR_ADD,
    NAVBAR_DEL,
} from './types';

const NAVBAR_STORAGE_KEY = 'NAVBAR_STORAGE_KEY';
const NAVBARS_STORAGE_KEY = 'NAVBARS_STORAGE_KEY';

const initialState = {
    error: false,
    navbar: null,
    navbars: null,
};

const getters = {
    navbars: function (state) {
        return state.navbars;
    },
    navbar: state => state.navbar,
};

const mutations = {
    [NAVBAR_OK](state) {
        console.log('NAVBAR_OK')
        state.error = false;
    },
    [NAVBAR_ERROR](state, do_logout = false) {
        console.log('NAVBAR_ERROR')
        state.error = true;
        if (do_logout) {
            router.push('/logout');
        }
    },
    [NAVBARS_SET](state, navbars) {
        console.log('NAVBARS_SET ', navbars)
        state.error = false;
        set_item(NAVBARS_STORAGE_KEY, navbars);
        state.navbars = navbars;
    },
    [NAVBAR_SET](state, navbar) {
        state.error = false;
        set_item(NAVBAR_STORAGE_KEY, navbar);
        state.navbar = navbar;
    },
    [NAVBAR_ADD](state, navbar) {
        console.log('NAVBAR_ADD ', navbar)
        state.error = false;
        state.navbars = [navbar, ...state.navbars]
    },
    [NAVBAR_DEL](state, {id}) {
        console.log('NAVBAR_DEL ');
        state.error = false;
        del_item(NAVBAR_STORAGE_KEY);
        state.navbar = null;
        if (state.navbars.filter) {
            state.navbars = state.navbars.filter(
                navbar => {
                    return navbar.id !== id
                }
            );
        }

    },
};


const actions = {
    list({commit}, {data}) {
        console.log('store navbar list data=', data);
        return API.navbar.list(data)
        .then(function (data) {
            commit(NAVBARS_SET, data);
            return data
        })
        .catch(function (error) {
            console.log('error: ', error);
            commit(NAVBAR_ERROR);
        });
    },
    get({commit}, {id}) {
        console.log('store navbar get, ', id);
        return API.navbar.get(id)
        .then(function (data) {
            commit(NAVBAR_SET, data);
            return data;
        })
        .catch(function (error) {
            console.log('error: ', error);
            commit(NAVBAR_ERROR);
        });
    },
    update({commit}, {id, data, self}) {
        console.log('store navbar update, ', id, data, self);
        return API.navbar.update(id, data)
        .then(function (data) {
            let messg = self.get_text('navbar') + ' - ' + self.get_text('update_success');
            console.log('store navbar update, ', self);
            Notice.success(
                messg,
                self
            );
            commit(NAVBAR_SET, data);
            return data;
        })
        .catch(function (error) {
            let messg = self.get_text('navbar') + ' - ' + self.get_text('update_error');
            console.log('error: ', error);
            Notice.error(
                messg,
                error,
                self,
            );
            commit(NAVBAR_ERROR);
        });
    },
    create({commit}, {data, self}) {
        console.log('store navbar create, ', data);
        return API.navbar.create(data)
        .then(function (data) {
            let messg = self.get_text('navbar') + ' - ' + self.get_text('add_success');
            console.log('store navbar create, ', self);
            Notice.success(
                messg,
                self
            );
            commit(NAVBAR_SET, data);
            return data;
        })
        .catch(function (error) {
            let messg = self.get_text('navbar') + ' - ' + self.get_text('add_error');
            console.log('error: ', error);
            Notice.error(
                messg,
                error,
                self
            );
            commit(NAVBAR_ERROR);
        });
    },
    delete({commit}, {data, self}) {
        console.log('store navbar delete, ', data, self);
        return API.navbar.delete(data)
        .then(function (data) {
            let messg = self.get_text('navbar') + ' - ' + self.get_text('delete_success');
            Notice.success(
               messg,
                self
            );
            commit(NAVBAR_DEL,data);
            return data;
        })
        .catch(function (error) {
            let messg = self.get_text('navbar') + ' - ' + self.get_text('delete_error');
            console.log('error: ', error);
            Notice.error(
                messg,
                error,
                self
            );
            commit(NAVBAR_ERROR);
        });
    },
    search({commit}, {data}) {
        console.log('search=', data);
        return API.search.list(data)
        .then(function (data) {
            return data
        })
        .catch(function (error) {
            console.log('error: ', error);
            commit(NAVBAR_ERROR);
        });
    },


    // нач состояние. Смотрим, если в хранилище есть - просовывыаем
    initialize({commit}) {
        console.log('store navbar init');
        const navbar = get_item(NAVBAR_STORAGE_KEY);
        const navbars = get_item(NAVBARS_STORAGE_KEY);

        if (navbar) {
            commit(NAVBAR_SET, navbar);
        } else {
            commit(NAVBAR_SET, null);
        }
        if (navbars) {
            commit(NAVBARS_SET, navbars);
        } else {
            commit(NAVBARS_SET, null);
        }
    },
};


export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
