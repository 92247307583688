<template>
<div class="pages-list">

    <div class="row loading" v-if="loading">
        <div class="col-12 h-100">
            <div class="row backwhiteopacity"></div>
            <div class="row align-content-center h-100 py-5">
                <div class="col-12">
                    <div class="row justify-content-center">Loading...</div>
                </div>
                <div class="col-12">
                    <div class="row justify-content-center">
                        <i class="material-icons rotateinfinite">autorenew</i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-xl" v-if="page && !loading">
        <v-layout row wrap>
            <v-flex xs 10 column wrap>
                <v-card>
                    <v-flex class="card-padding" xs12>
                        <span v-html="page.html"></span>
                    </v-flex>

                    <!--
                    <p hidden>
                        <span v-html="page.hidden_text"></span>
                    </p>
                    -->

                </v-card>
                <br>
            </v-flex>
        </v-layout>
    </div>

    <div v-else id="lost-view">
        <h1>{{get_text('page_not_found')}}</h1>
        <h4>{{get_text('one_of_us_is_lost')}}</h4>
        <router-link to="/">{{get_text('take_me_home')}}</router-link>
    </div>

</div>
</template>

<script>
    import store from '../store';
    export default {
        name: "Page",
        data() {
            return {
                inputdata: null,
                orderingBy: null,
                paginationTotal: null,
                paginateBy: null,
                currPage: 1,
                nextPage: null,
                prevPage: null,
                loading: false,
                txt: {},
                lang_id: null,
                page: null,
            };
        },
        watch: {
            '$route.params': function (id) {
                this.fetchData();
            },
            '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'];
                this.fetchData();
            },
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                console.log('Page fetchData');
                this.txt = store.getters['summary/texts'] || {};
                this.lang_id = store.getters['auth/lang_id'] || 1;

                let params = this.$route.params;
                console.log('params', params);
                var cid = '';
                cid = params['pathMatch'];

                let data = {};
                if (isNaN(cid)){
                    // not a number
                    data['name'] = cid;
                    data['language'] = this.lang_id;
                } else {
                    data['id'] = cid;
                }

                this.loading = true;
                this.$store.dispatch({
                    type: 'page/list',
                    data: data,
                })
                .then(page => {
                    this.page = page.results[0];
                    document.title = this.page.title + ' Dia-status';
                    this.loading = false;

                    // 
                    if (this.page.html_done && !this.page.need_work){
                        console.log('this.page.html = this.page.html_done');
                        this.page.html = this.page.html_done;
                    }
                });
            },
            get_text(mnemonic) {
                return (this.txt[mnemonic] || {})[this.lang_id];
            },
        },

    }
</script>

<style>
img {
    max-width:100%;
    height:auto;
}
a {
    word-break: break-word;
}
.card-padding {
  padding: 5px;
}
.pages-list {
  flex-basis: 100%;
  padding: 25px 0;
}

@media (max-width: 768px) {
  .pages-list {
    flex-basis: 100%;
    padding: 0;
  }
}
</style>