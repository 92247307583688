<template>
    <v-container grid-list-xl>
        <v-layout row wrap>
            <v-flex xs6 offset-xs4 column wrap>
                <v-card>
                    <v-flex xs12>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <br>
                            <h3>{{get_text('new_register')}}</h3>
                            <br>
                            <v-text-field
                                    v-model="new_user.username"
                                    :label="get_text('user_login')"
                                    :placeholder="get_text('user_login')"
                                    :rules="required"
                                    maxlength="20"
                                    required
                            ></v-text-field>
                            <v-text-field
                                    v-model="new_user.email"
                                    :label="get_text('email')"
                                    :placeholder="get_text('email')"
                                    :rules="emailRules"
                                    maxlength="35"
                                    required
                            ></v-text-field>
                            <v-text-field
                                    v-model="new_user.password"
                                    :label="get_text('password')"
                                    :placeholder="get_text('password')"
                                    :append-icon="pass_show1 ? 'visibility_off' : 'visibility'"
                                    :type="pass_show1 ? 'text' : 'password'"
                                    @click:append="pass_show1 = !pass_show1"
                                    :rules="password_min"
                                    maxlength="20"
                                    required
                            ></v-text-field>
                            <v-text-field
                                    v-model="confirm_password"
                                    :label="get_text('repeat_password')"
                                    :placeholder="get_text('repeat_password')"
                                    :append-icon="pass_show2 ? 'visibility_off' : 'visibility'"
                                    :type="pass_show2 ? 'text' : 'password'"
                                    @click:append="pass_show2 = !pass_show2"
                                    :rules="password_rules"
                                    maxlength="20"
                                    required
                            ></v-text-field>
                            <v-text-field
                                    v-model="new_user.first_name"
                                    :label="get_text('name')"
                                    :placeholder="get_text('name')"
                                    :rules="required"
                                    maxlength="29"
                                    required
                            ></v-text-field>
                            <v-text-field
                                    v-model="new_user.last_name"
                                    :label="get_text('surname')"
                                    :placeholder="get_text('surname')"
                                    :rules="required"
                                    maxlength="29"
                                    required
                            ></v-text-field>
                            <v-select
                                    v-model="new_user.lang"
                                    :label="get_text('native_language')"
                                    required
                                    :items="language_list"
                                    item-text="name"
                                    item-value="id"
                            ></v-select>
                            <v-btn
                                    @click="register()"
                                    :disabled="!valid"
                                    color="green"
                            >
                               {{get_text('registration')}}
                            </v-btn>
                            <br>
                            <span v-if="error" style="color: red;">{{ error_mes }}</span>
                        </v-form>
                    </v-flex>
                </v-card>
                <br>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import store from '../store';
    import {Notice} from "../store/notice";

    export default {
        name: "Register",
        data() {
            return {
                loading: false,
                txt: {},
                lang_id: null,
                page: null,
                valid: false,
                new_user: {
                    lang: 2,
                    email: null,
                    password: null,
                },
                pass_show1: false,
                pass_show2: false,
                new_password: null,
                confirm_password: null,
                error: false,
                error_mes: '',
                language_list: null,
                required: [
                    (v) => !!v || this.get_text('required'),
                    (v) => !/[&+,:;=?#|'<>^*()%!\s]/g.test(v) || this.get_text('not_allowed_symbols')
                ],
                password_rules: [
                    (v) => !!v || this.get_text('required'),
                    (v) => v == this.new_user.password || this.get_text('password_match')
                ],
                emailRules: [
                    (v) => !!v || this.get_text('required'),
                    (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.get_text('invalid_email')
                ],
                password_min: [
                    (v) => !!v || this.get_text('required'),
                    (v) => !/[&+,:;=?#|'<>^*()%!\s]/g.test(v) || this.get_text('not_allowed_symbols'),
                    (v) => v.length >= 6 || this.get_text('password_min'),
                ]


            };
        },
        watch: {
            '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'];
                this.fetchData();
                this.validate();
            },
        },
        created() {
            this.fetchData();
        },

        methods: {
            fetchData() {
                console.log('Register fetchData');
                this.txt = store.getters['summary/texts'] || {};
                this.lang_id = store.getters['auth/lang_id'] || 1;
                this.language_list = store.getters['summary/language_list'];
            },
            get_text(mnemonic) {
                return (this.txt[mnemonic] || {})[this.lang_id];
            },
            validate() {
                if (this.$refs.form.validate()) {
                    console.log('Валидация при смене языка')
                }
            },
            register() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    this.$store.dispatch({
                            type: 'message/register',
                            data: this.new_user,
                        })
                        .then(
                            mes => {
                                console.log('ответ с регистрации',mes);
                                if (mes.status=='Error') {
                                    if (mes.message=='Такое имя пользователя уже существует') {
                                        this.error_mes = this.get_text('invalid_username');
                                        this.loading = false;
                                    }
                                    else {
                                        this.error_mes = mes.message;
                                        this.loading = false;
                                    }
                                    this.error = true;
                                }
                                else {
                                    this.$store.dispatch({
                                        type: 'auth/login',
                                        data: {
                                            username: this.new_user.username,
                                            password: this.new_user.password,
                                        },
                                    }).then(() => {
                                        this.$store.dispatch({
                                            type: 'summary/initialize',
                                        }).then(mes => {
                                            if ( store.getters['auth/error']) {
                                                console.log('ошибка входа',);
                                                this.error = true;
                                                this.loading = false;
                                            }
                                            else {
                                                let self = this;
                                                let messg = this.get_text('registration_was_successful') + ' '
                                                + this.new_user.first_name + '!';
                                                Notice.success(messg, self);
                                                this.$router.push('/profile');
                                            }
                                        });
                                    });
                                }
                            }
                        );
                }
            }
        },

    }
</script>

<style scoped>
</style>