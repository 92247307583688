<template>
    <v-container grid-list-xl>
        <v-layout row wrap>
            <v-flex xs6 offset-xs4 column wrap>
                <v-card>
                    <v-flex xs12>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <br>
                            <h3>{{get_text('login')}}</h3>
                            <br>
                            <v-text-field
                                    v-model="inputs.username"
                                    :label="get_text('username')"
                                    :placeholder="get_text('username')"
                                    :rules="required"
                                    maxlength="20"
                                    required
                            ></v-text-field>
                            <v-text-field
                                    v-model="inputs.password"
                                    :label="get_text('password')"
                                    :placeholder="get_text('password')"
                                    required
                                    :append-icon="pass_show1 ? 'visibility_off' : 'visibility'"
                                    :type="pass_show1 ? 'text' : 'password'"
                                    @click:append="pass_show1 = !pass_show1"
                                    maxlength="20"
                                    required
                                    :rules="required"
                            ></v-text-field>
                            <v-btn
                                    v-if="txt"
                                    @click="login(inputs)"
                                    :disabled="!valid"
                                    id="login-button"
                                    color="green"
                            >
                                   {{get_text('login')}}
                            </v-btn>
                            <v-btn
                                    v-if="txt"
                                    @click="reset()"
                                    id="login-button"
                                    color="orange"
                            >
                                   {{get_text('reset_password')}}
                            </v-btn>
                            <br>
                            <span v-if="error" style="color: red;">
                                {{get_text('password_login_error')}}
                            </span>
                        </v-form>
                    </v-flex>
                </v-card>
                <br>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import store from '../store';

    export default {
        data() {
            return {
                inputs: {
                    username: '',
                    password: '',
                },
                valid: false,
                txt: null,
                lang_id: null,
                error: false,
                pass_show1: false,
                required: [
                    (v) => !!v || this.get_text('required'),
                ],
            };
        },

        created() {
            this.fetchData();
        },

        watch: {
            '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'] || 1;
                this.fetchData();
            },
        },

        methods: {
            login({username, password}) {
                console.log('try to login', username);
                this.$store.dispatch({
                    type: 'auth/login',
                    data: {
                        username: username,
                        password: password,
                    },
                }).then(() => {
                    this.$store.dispatch({
                        type: 'summary/initialize',
                    }).then(mes => {
                        if ( store.getters['auth/error']) {
                            console.log('ошибка входа',);
                            this.error = true;
                        }
                        else {
                            this.$router.push('/blogs')
                        }
                    });
                });
            },

            fetchData() {
                store.dispatch('summary/initialize')
                    .then(() => {
                        this.txt = store.getters['summary/texts'] || {};
                        this.lang_id = store.getters['auth/lang_id'] || 1;
                    });
                console.log('TXT', this.txt);
            },

            get_text(mnemonic) {
                return (this.txt[mnemonic] || {})[this.lang_id];
            },
            reset() {
                this.$router.push('/reset')
            },
        },
    };

</script>

<style>

</style>
