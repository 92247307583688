<template>
    <v-footer height="40" absolute>
        <v-layout justify-center row wrap>
            <v-flex
                v-if="txt"
                info
                py-1
                text-xs-center
                white--text
                xs12
                align-center
                fill-height
            >
                &copy; {{curYear}} — Блог Нюры Шариковой (diastatus@yandex.ru)
            </v-flex>
        </v-layout>
    </v-footer>
</template>

<script>
    import store from '../store';
    import {mapGetters} from 'vuex';

    export default {
        name: "footer",

        data() {
            return {
                curYear: (new Date()).getFullYear(),
                lang_id: null,
                txt: null,
            };
        },

        created() {
            this.fetchData();
        },

        watch: {
             '$store.state.auth.language_id'() {
                this.lang_id = store.getters['auth/lang_id'] || 1;
                this.fetchData();
            },
        },

        methods: {
              fetchData() {
                  this.txt = store.getters['summary/texts'] || {};
                  this.lang_id = store.getters['auth/lang_id'] || 1;
              },
              get_text(mnemonic) {
                  return (this.txt[mnemonic] || {})[this.lang_id];
              },
        },
    }
</script>