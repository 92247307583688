import session from './session';
import _router from '../router';

class APIconstructor {
    constructor(model) {
        this.model = model;
    };

    create(data) {
        console.log('API create ', data);
        return session.post(
            `/core/api/${this.model}/`,
            data
        )
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            if (e.response.status === 403) {
                return _router.push('/logout');
            } else {
                throw e;
            }
        });
    };

    list(data = {}) {
        console.log('API list ', data);

        // Параметры в строку. Порно, но пока хз как иначе
        let str = '?';
        for (const [key, value] of Object.entries(data)) {
            str = str + key + '=' + value + '&';
        }
        return session.get(
            `/core/api/${this.model}/${str}`
        )
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            if (e.response.status === 403) {
                return _router.push('/logout');
            } else {
                throw e;
            }
        });
    };

    get(id) {
        console.log('API get ', id);
        return session.get(
            `/core/api/${this.model}/${id}/`
        )
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            if (e.response.status === 403) {
                return _router.push('/logout');
            } else {
                throw e;
            }
        });
    };

    update(id, data) {
        console.log('API put ', id, data);
        return session.put(
            `/core/api/${this.model}/${id}/`,
            data,
        )
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            if (e.response.status === 403) {
                return _router.push('/logout');
            } else {
                throw e;
            }
        });
    };

    delete(data) {
        console.log('API delete ', data);
        return session.delete(
            `/core/api/${this.model}/${data.id}/`
        )
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            if (e.response.status === 403) {
                return _router.push('/logout');
            } else {
                throw e;
            }
        });
    };
};

class APIconstructorCustom {
    constructor(model) {
        this.model = model;
    };

    post(data) {
        console.log('custom API post ', data);
        let str = '';
        for (const [key, value] of Object.entries(data)) {
            if (key === 'message' && (value.includes('+') || value.includes('&'))) {
                str = str + key + '=' + encodeURIComponent(value) + '&';
            } else {
                str = str + key + '=' + value + '&';
            }
        }
        return session.post(
            `/api/${this.model}/`,
            str
        )
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            if (e.response.status === 403) {
                return _router.push('/logout');
            }
        });
    };

    get(data = {}) {
        console.log('custom API get param ', data);

        // Параметры в строку. Порно, но пока хз как иначе
        let str = '?';
        for (const [key, value] of Object.entries(data)) {
            str = str + key + '=' + value + '&';
        }
        return session.get(
            `/api/${this.model}${str}`
        )
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            if (e.response.status === 403) {
                return _router.push('/logout');
            }
        });
    };

    lp(user_id) {
        console.log('Lp request', user_id);
        return session.get(
            `/sub/message_channel${user_id}.b20`
        )
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            if (e.response.status > 400) {
                return {"type": "no_long_pooling"}
            } else {
                return null;
            }
        });
    };

    vk(data) {
        console.log('custom API vk ', data);
        return session.post(
            `/vk/${this.model}/`,
        )
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            if (e.response.status === 403) {
                return _router.push('/logout');
            }
        });
    };

    reset(data) {
        console.log('custom API password reset ', data);
        let str = '';
        for (const [key, value] of Object.entries(data)) {
            str = str + key + '=' + value + '&';
        }
        return session.post(
            `/api/password/${this.model}`,
            str
        )
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            if (e.response.status === 403) {
                return _router.push('/logout');
            }
        });
    }
}


export {
    APIconstructor as default,
    APIconstructorCustom,
};