export const ACTIVATION_BEGIN = 'ACTIVATION_BEGIN';
export const ACTIVATION_CLEAR = 'ACTIVATION_CLEAR';
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';

export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_CLEAR = 'LOGIN_CLEAR';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';

export const PROFILE_OK = 'PROFILE_OK';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const PROFILE_SET = 'PROFILE_SET';
export const PROFILE_DEL = 'PROFILE_DEL';

export const SUMMARY_OK = 'SUMMARY_OK'
export const SUMMARY_SET = 'SUMMARY_SET'
export const SUMMARY_DEL = 'SUMMARY_DEL'
export const SUMMARY_ERROR = 'SUMMARY_ERROR'

export const STPC_SET = 'STPC_SET'

export const PAGE_OK = 'PAGE_OK';
export const PAGE_ERROR = 'PAGE_ERROR';
export const PAGES_SET = 'PAGES_SET';
export const PAGE_SET = 'PAGE_SET';
export const PAGE_ADD = 'PAGE_ADD';
export const PAGE_DEL = 'PAGE_DEL';

export const PAGE_L_OK = 'PAGE_L_OK';
export const PAGE_L_ERROR = 'PAGE_L_ERROR';
export const PAGES_L_SET = 'PAGES_L_SET';
export const PAGE_L_SET = 'PAGE_L_SET';
export const PAGE_L_ADD = 'PAGE_L_ADD';
export const PAGE_L_DEL = 'PAGE_L_DEL';

export const MEDIA_OK = 'MEDIA_OK';
export const MEDIA_ERROR = 'MEDIA_ERROR';
export const MEDIAS_SET = 'MEDIAS_SET';
export const MEDIA_SET = 'MEDIA_SET';
export const MEDIA_ADD = 'MEDIA_ADD';
export const MEDIA_DEL = 'MEDIA_DEL';

export const NAVBAR_OK = 'NAVBAR_OK';
export const NAVBAR_ERROR = 'NAVBAR_ERROR';
export const NAVBARS_SET = 'NAVBARS_SET';
export const NAVBAR_SET  = 'NAVBAR_SET';
export const NAVBAR_ADD = 'NAVBAR_ADD';
export const NAVBAR_DEL = 'NAVBAR_DEL';

export const TAG_OK = 'TAG_OK';
export const TAG_ERROR = 'TAG_ERROR';
export const TAGS_SET = 'TAGS_SET';
export const TAG_SET  = 'TAG_SET';
export const TAG_ADD = 'TAG_ADD';
export const TAG_DEL = 'TAG_DEL';

