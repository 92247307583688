import API from '../api/api';
import router from '../router';
import {get_item, set_item, del_item,} from './storage';
import {Notice} from "./notice";
import {
    DT_OK,
    DT_ERROR,
    DTS_SET,
    DT_SET,
    DT_ADD,
    DT_DEL,
} from './types';

const DT_STORAGE_KEY = 'DT_STORAGE_KEY';
const DTS_STORAGE_KEY = 'DTS_STORAGE_KEY';

const initialState = {
    error: false,
    data: null,
    datas: null,
};

const getters = {
    datas: function (state) {
        console.log('getter: datas');
        console.log(state);
        return state.datas;
    },
    data: state => state.data,
};

const mutations = {
    [DT_OK](state) {
        console.log('DT_OK')
        state.error = false;
    },
    [DT_ERROR](state, do_logout = false) {
        console.log('DT_ERROR')
        state.error = true;
        if (do_logout) {
            router.push('/logout');
        }
    },
    [DTS_SET](state, dts) {
        console.log('DTS_SET')
        state.error = false;
        set_item(DTS_STORAGE_KEY, dts);
        state.datas = dts;
        console.log('datas=')
        console.log(dts)
    },
    [DT_SET](state, dt) {
        console.log('DT_SET')
        console.log(dt)
        state.error = false;
        set_item(DT_STORAGE_KEY, dt);
        state.data = dt;
    },
    [DT_ADD](state, dt) {
        console.log('DT_ADD')
        state.error = false;
        state.datas = [dt, ...state.datas]
    },
    [DT_DEL](state, {id}) {
        console.log('DT_DEL');
        state.error = false;
        del_item(DT_STORAGE_KEY);
        state.data = null;
        if (state.datas.filter) {
            state.datas = state.datas.filter(
                data => {
                    return data.id !== id
                });
        }
    },

};


const actions = {
    list({commit}, {data}) {
        console.log('store data list data=', data);
        return API.data.list(data)
            .then(function (data) {
                //console.log(data);
                commit(DTS_SET, data);
                return data
            })
            .catch(function (error) {
                console.log('error: ', error);
                commit(DT_ERROR);
            });
    },
    get({commit}, {id}) {
        console.log('store data get, ', id);
        return API.data.get(id)
            .then(function (data) {
                commit(DT_SET, data);
                return data;
            })
            .catch(function (error) {
                console.log('error: ', error);
                commit(DT_ERROR);
            });
    },
    update({commit}, {id, data, self}) {
        console.log('store data update, ', id, data, self);
        return API.data.update(id, data)
            .then(function (data) {
                Notice.success(
                    self.get_text("success_data_save"),
                    self
                );
                commit(DT_SET, data);
                return data;
            })
            .catch(function (error) {
                console.log('error: ', error);
                Notice.error(
                    self.get_text("error_data_save"),
                    error,
                    self
                );
                commit(DT_ERROR);
            });
    },
    create({commit}, {data, self}) {
        console.log('store data create, ', data, self);
        return API.data.create(data)
            .then(function (data) {
                Notice.success(
                    self.get_text("success_data_add"),
                    self
                );
                commit(DT_SET, data);
                return data;
            })
            .catch(function (error) {
                console.log('error: ', error);
                Notice.error(
                    self.get_text("error_data_add"),
                    error,
                    self,
                );
                commit(DT_ERROR);
            });
    },
    delete({commit}, {data, self}) {
        console.log('store responsent delete, ', data, self);
        return API.data.delete(data)
            .then(function (data) {
                Notice.success(
                    self.get_text("success_data_delete"),
                    self,
                );
                commit(DT_DEL, data);
                return data;
            })
            .catch(function (error) {
                console.log('error: ', error);
                Notice.error(
                    self.get_text("error_data_delete"),
                    error,
                    self,
                );
                commit(DT_ERROR);
            });
    },


    // нач состояние. Смотрим, если в хранилище есть - просовывыаем
    initialize({commit}) {
        console.log('data store init')
        const dt = get_item(DT_STORAGE_KEY);
        const dts = get_item(DTS_STORAGE_KEY);

        if (dt) {
            commit(SET_DT, dt);
        } else {
            commit(SET_DT, null);
        }
        if (dts) {
            commit(SET_DTS, dts);
        } else {
            commit(SET_DTS, null);
        }
    },
};


export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
