import API from '../api/api';
import router from '../router';
import {get_item, set_item, del_item,} from './storage';
import {Notice} from "./notice";
import {
    MEDIA_OK,
    MEDIA_ERROR,
    MEDIAS_SET,
    MEDIA_SET,
    MEDIA_ADD,
    MEDIA_DEL,
} from './types';

const MEDIA_STORAGE_KEY = 'MEDIA_STORAGE_KEY';
const MEDIAS_STORAGE_KEY = 'MEDIAS_STORAGE_KEY';

const initialState = {
    error: false,
    media: null,
    medias: null,
};

const getters = {
    medias: function (state) {
        return state.medias;
    },
    media: state => state.media,
};

const mutations = {
    [MEDIA_OK](state) {
        console.log('MEDIA_OK')
        state.error = false;
    },
    [MEDIA_ERROR](state, do_logout = false) {
        console.log('MEDIA_ERROR')
        state.error = true;
        if (do_logout) {
            router.push('/logout');
        }
    },
    [MEDIAS_SET](state, medias) {
        console.log('MEDIAS_SET ', medias)
        state.error = false;
        set_item(MEDIAS_STORAGE_KEY, medias);
        state.medias = medias;
    },
    [MEDIA_SET](state, media) {
        state.error = false;
        set_item(MEDIA_STORAGE_KEY, media);
        state.media = media;
    },
    [MEDIA_ADD](state, media) {
        console.log('MEDIA_ADD ', media)
        state.error = false;
        state.medias = [media, ...state.medias]
    },
    [MEDIA_DEL](state, {id}) {
        console.log('MEDIA_DEL ');
        state.error = false;
        del_item(MEDIA_STORAGE_KEY);
        state.media = null;
        if (state.medias.filter) {
            state.medias = state.medias.filter(
                media => {
                    return media.id !== id
                }
            );
        }

    },
};


const actions = {
    list({commit}, {data}) {
        console.log('store media list data=', data);
        return API.mediafile.list(data)
            .then(function (data) {
                commit(MEDIAS_SET, data);
                return data
            })
            .catch(function (error) {
                console.log('error: ', error);
                commit(MEDIA_ERROR);
            });
    },
    get({commit}, {id}) {
        console.log('store media get, ', id);
        return API.mediafile.get(id)
            .then(function (data) {
                commit(MEDIA_SET, data);
                return data;
            })
            .catch(function (error) {
                console.log('error: ', error);
                commit(MEDIA_ERROR);
            });
    },
    update({commit}, {id, data, self}) {
        console.log('store media update, ', id, data, self);
        return API.mediafile.update(id, data)
            .then(function (data) {
                let messg = self.get_text('media') + ' - ' + self.get_text('update_success');
                console.log('store media update, ', self);
                Notice.success(
                    messg,
                    self
                );
                commit(MEDIA_SET, data);
                return data;
            })
            .catch(function (error) {
                let messg = self.get_text('media') + ' - ' + self.get_text('update_error');
                console.log('error: ', error);
                Notice.error(
                    messg,
                    error,
                    self,
                );
                commit(MEDIA_ERROR);
            });
    },
    create({commit}, {data, self}) {
        console.log('store media create, ', data);
        return API.mediafile.create(data)
            .then(function (data) {
                let messg = self.get_text('media') + ' - ' + self.get_text('add_success');
                console.log('store media create, ', self);
                Notice.success(
                    messg,
                    self
                );
                commit(MEDIA_SET, data);
                return data;
            })
            .catch(function (error) {
                let messg = self.get_text('media') + ' - ' + self.get_text('add_error');
                console.log('error: ', error);
                Notice.error(
                    messg,
                    error,
                    self
                );
                commit(MEDIA_ERROR);

            });
    },
    delete({commit}, {data, self}) {
        console.log('store media delete, ', data, self);
        return API.mediafile.delete(data)
            .then(function (data) {
                let messg = self.get_text('media') + ' - ' + self.get_text('delete_success');
                Notice.success(
                    messg,
                    self
                );
                commit(MEDIA_DEL,data);
                return data;
            })
            .catch(function (error) {
                let messg = self.get_text('media') + ' - ' + self.get_text('delete_error');
                console.log('error: ', error);
                Notice.error(
                    messg,
                    error,
                    self
                );
                commit(MEDIA_ERROR);
            });
    },
    search({commit}, {data}) {
        console.log('search=', data);
        return API.search.list(data)
            .then(function (data) {
                return data
            })
            .catch(function (error) {
                console.log('error: ', error);
                commit(MEDIA_ERROR);
            });
    },



    // нач состояние. Смотрим, если в хранилище есть - просовывыаем
    initialize({commit}) {
        console.log('store media init');
        const media = get_item(MEDIA_STORAGE_KEY);
        const medias = get_item(MEDIAS_STORAGE_KEY);

        if (media) {
            commit(MEDIA_SET, media);
        } else {
            commit(MEDIA_SET, null);
        }
        if (medias) {
            commit(MEDIAS_SET, medias);
        } else {
            commit(MEDIAS_SET, null);
        }
    },
};


export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
