<template>
    <v-app id="app">
        <div>
            <vue-headful
                title="Dia-status"
                description="Блог Нюры Шариковой"
            />
        </div>

        <div id="app" class="container-fluid" v-if="summary">
            <div class="row">
                <main_header></main_header>
                <navbar></navbar>
                <router-view></router-view>
                <foot></foot>
            </div>
        </div>
    </v-app>
</template>

<script>
    import store from './store';
    import {mapGetters} from 'vuex';
    import Navbar from './components/Navbar.vue';
    import Footer from './components/Footer.vue';
    import Header from './components/Header.vue';
    import Vue from "vue";
    import CripNotice from "crip-vue-notice";
    Vue.use(CripNotice, {
        // You can set default duration for all notices on init.
        duration: 7,

        // Style of notices wrapper where will appear notices.
        styles: {top: "15px", right: "15px"},

        // You may add custom class for wrapper to define own styles.
        className: "notices",
        title: "Notice title",
        description: "Description under title",

        // Font awesome icon class definitions for all notice types.
        icons: {
            info: "fa fa-info-circle",
            success: "fa fa-check-circle",
            warning: "fa fa-exclamation-circle",
            error: "fa fa-times-circle",
            close: "fa fa-times",
        }
    });

    Vue.extend({
        mounted() {

            this.notice = new CripNotice({
                // Unique name to be able close it programmatically.
                name: "unique-notice-name",

                // Notice title.
                title: "Notice title",

                // Notice description.
                description: "Description under title",

                // Callback to react on notice close action.
                onClose: () => console.log("notice now is closed"),

                // Custom styles for current notice.
                styles: {padding: "5px;"},

                // Custom duration of current notice.
                duration: 7,

                // Enables close icon for notice.
                closable: true,

                // Type of the notice: "error" | "warning" | "success" | "info" | "normal"
                type: "normal"
            })
        },

        methods: {
            close() {
                this.notice.close()
                // or this.$notice.close("unique-notice-name")
            }
        }
    });
    export default {
        name: 'app',
        components: {
            'main_header': Header,
            'navbar': Navbar,
            'foot': Footer,
            'CripNotice': CripNotice,
        },
        computed: mapGetters('auth', [
            'isAuthenticated',
        ]),
        data() {
            return {
                summary: null,
            }
        },
        methods: {
            fetchSummary() {
                store.dispatch('summary/get')
                .then(() => {
                    this.summary = true;
                });
            },
        },
        beforeCreate() {
            // fetch the data when the view is created and the data is already being observed
            store.dispatch('summary/get')
            .then(() => {
                this.summary = true;
            });

            /*
            // каждые 5 минут подтягиваем статику
            setInterval(
                () => {
                    this.fetchSummary();
                },
                1000 * 60 * 5
            );
            */
        },

        /*
        metaInfo() {
            return {
                title: 'Нюра Шарикова - о жизни с сахарным диабетом',
                meta: [
                    {
                        name: 'description',
                        content:  'Блог Нюры Шариковой о жизни с сахарным диабетом',
                    },
                    {
                        property: 'og:title',
                        content: 'Нюра Шарикова - о жизни с сахарным диабетом',
                    },
                    {
                        property: 'og:description',
                        content: 'Нюра Шарикова - о жизни с сахарным диабетом',
                    },
                    {
                        property: 'og:site_name',
                        content: 'dia-status',
                    },
                    {
                        property: 'og:type',
                        content: 'website',
                    },
                    {
                        property: 'og:image',
                        content: '/img/ava.png',
                    },
                    {
                        name: 'robots',
                        content: 'index,follow,max-image-preview:large',
                    },
                ],
            };
        }
        */

    };
</script>

<style>
    /*
        Matches anywhere: .col-md, .left-col, .col, .tricolor, etc.
        [class*="col"]

        Matches at the beginning: .col-md, .col-sm-6, etc.
        [class^="col-"]

        Matches at the ending: .left-col, .right-col, etc.
        [class$="-col"]
    */

    /*
    [class^="col"]{
        padding: 5px !important;
    }
    */

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    .bordered {
        border: thin solid black;
    }

    .material-icons.md-18 {
        font-size: 18px;
    }

    .material-icons.md-24 {
        font-size: 24px;
    }

    .material-icons.md-36 {
        font-size: 36px;
    }

    .material-icons.md-48 {
        font-size: 48px;
    }

    table {
        border-collapse: collapse;
    }

    table, th, td {
        border: 1px solid black;
    }

    tr a, .content a {
        color: #0000EE !important;
    }

    th {
        color: black !important;
        font-weight: bold !important;
    }

    .pull-right {
        float: right !important;
    }

    div.loading {
        width: 100%;
        height: 100%;
        z-index: 1000;
        font-size: 30px;
        font-weight: 700;
        position: absolute;
        min-height: 200px;
    }

    .backwhiteopacity {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: white;
        opacity: 0.5;

    }

    .rotateinfinite {
        -webkit-animation: spin 1s linear infinite;
        -moz-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }

    .clear {
        clear: both;
    }

    .indexfront {
        z-index: 20;
        width: 100%;
        height: 100%;
        text-align:center;
        display: flex;
        border-bottom: 1px solid black;
        border-top: 1px solid black;
        background-color: white;
        position:relative;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    div.v-data-table__wrapper{
      overflow: initial !important;
    }

    .fixed-header div.v-table__overflow {
        width: 100%;
        overflow-y: auto;
        border-top: none !important;
    }
    .fixed-header table.v-datatable.v-table {
        width: auto !important;
        min-width: 100%;
        border: none;
    }
    .fixed-header table.v-table thead tr:first-child {
        border: none !important;
    }
    .fixed-header table.v-table thead th {
        padding: 0 !important;
        height: 100%;
        border-top: none !important;
        border-bottom: none !important;
    }
    .fixed-header table.v-table thead {
        border: none !important;
    }
    .fixed-header {
        max-height: 500px;
        flex-direction: column;
        overflow-x: auto;
    }
    .fixed-header th {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 20;
        text-align: center;

    }
    .fixed-header th .material-icons {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    i.material-icons.rotateinfinite {
        font-size: 80px;
    }

    .ck-editor__editable {
        min-height: 300px;
    }
    .ck-editor {
        min-height: 300px;
        min-width: 100%;
    }


</style>




